export default (_this) => {
  return {
    trackDraftGA(eventObj) {
      _this.$skGA.emitEvent(eventObj);
      _this.$skGA.makeCheckout({step: 2, option: `${eventObj.eventCategory}_${eventObj.eventLabel}`});
    },
    trackCartEventsGA(eventType, productObj) {
      eventType === 'add' ? _this.$skGA.addToCart(productObj) : _this.$skGA.removeFromCart(productObj);
    },
    trackSubmitGA(purchaseObj) {
      _this.$skGA.emitEvent({event: 'event', eventCategory: purchaseObj.eventCategory, eventAction: 'submit', eventLabel: purchaseObj.eventLabel, value: purchaseObj.value});
      _this.$skGA.makeCheckout({step: 4, option: purchaseObj.eventCategory});
      _this.$skGA.purchase(purchaseObj);
    },
    trackSignInGA(checkoutOption) {
      _this.$skGA.emitEvent({eventCategory: 'authentication', eventAction: 'submit', eventLabel: 'signin', value: 0});
      _this.$skGA.makeCheckout({step: 1, option: `signin_${checkoutOption}`});
    },
    trackSignUpGA(checkoutOption) {
      _this.$skGA.emitEvent({eventCategory: 'authentication', eventAction: 'submit', eventLabel: 'signup', value: 0});
      _this.$skGA.makeCheckout({step: 1, option: `signup_${checkoutOption}`});
    },
    trackReviewGA(option) {
      _this.$skGA.makeCheckout({step: 3, option: option});
    },
    trackCancelGA(refundObj) {
      _this.$skGA.emitEvent({event: 'event', eventCategory: 'job', eventAction: 'cancel', eventLabel: refundObj.eventLabel, value: 0});
      _this.$skGA.refund(refundObj.refundObj);
    },
    trackEditGA(editObj) {
      _this.$skGA.emitEvent({event: 'event', eventCategory: 'job', eventAction: 'edit', eventLabel: editObj.eventLabel, value: editObj.value});
      _this.$skGA.refund(editObj.refundObj);
      _this.$skGA.purchase(editObj);
    },
    trackOvertimeGA(overtimeObj) {
      this.trackCartEventsGA('add', overtimeObj.productObj);
      _this.$skGA.purchase(overtimeObj);
    }
  };
};
