// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/salita_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.step-2__text {\n  margin-bottom: 20px;\n}\n.step-2__text a {\n  text-decoration: underline;\n}\n.bank-account-number__field {\n  margin-right: 20px;\n}\n.step-2__section-block {\n  padding: 15px;\n  border: 1px solid rgb(194, 188, 188);\n  border-radius: 3px;\n}\n.step-2__input-wrapper {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.step-2__text--bold {\n  font-weight: bold;\n}\n.step-2__text-register--bold {\n  font-weight: bold;\n  font-size: 18px;\n}\n.step-2__text--margin {\n  margin-left: 20px;\n}\n.step-2__logo {\n  width: 150px;\n  height: 38px;\n  margin-top: 10px;\n  margin-bottom: 20px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;
