(<template>
  <article class="export-modal">
    <div class="export-modal__radio-buttons">
      <sk-radio v-for="exportFormat in exportFormatList"
                :key="exportFormat.value"
                :item-text="exportFormat.text"
                :item-value="exportFormat.value"
                :preselected-value="choosenExportFormat"
                @radiochanged="catchExportFormat" />
    </div>
    <div class="export-modal__btn-cont">
      <button class="sk-btn sk-btn--white export-modal__btn"
              type="button"
              @click.prevent="closeModal">{{ $gettext('Cancel') }}</button>
      <button class="sk-btn sk-btn--default export-modal__btn"
              type="button"
              @click.prevent="confirm">{{ $gettext('Export') }}</button>
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        choosenExportFormat: 'overview_report',
      };
    },
    computed: {
      exportFormatList() {
        return [
          {value: 'overview_report', text: this.$gettext('All')},
          {value: 'lov_report', text: this.$gettext('Interpretation Monitor LOV report')},
          {value: 'sprak_report', text: this.$gettext('Interpretation Monitor SPRÄK report')}
        ];
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      catchExportFormat(value) {
        this.choosenExportFormat = value;
      },
      confirm() {
        this.data.modalCallback(this.choosenExportFormat);
        this.closeModal();
      }
    }
  };
</script>

<style scoped>
.export-modal {
  padding: 10px 35px 30px;
}

.export-modal__radio-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 22px 0;
}

.export-modal__btn-cont {
  display: flex;
  justify-content: flex-end;
}

.export-modal__btn {
  width: auto;
  padding: 0 15px;
}

.export-modal__btn:first-child {
  margin-right: 15px;
}
</style>
