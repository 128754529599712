import Vue from 'vue';

export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      bookedAssignments: {
        progress: true,
        data: '',
      },
      availableAssignments: {
        progress: true,
        data: '',
      },
      // flag that determines if there are not opened supplier invitation
      newInvitation: false,
      todayJobs: '',
      tomorrowJobs: '',
      feedbackJobs: '',
    },
    actions: {
      getBookedAssignmentsDashboard({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getMyAssignments(params).then((response) => {
              if (response?.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              commit('setSDashboardBookedAssignments', response);
              resolve();
            }).catch((error) => {
              reject(error);
            });
        });
      },
      getAvailableAssignmentsDashboard({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getAvailableAssignments(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSDashboardAvailableAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getInvitedAssignmentDashboard({commit}, params) {
        return new Promise((resolve, reject) => {
          fetchData.getInvitedAssignmentDashboard(params).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setSDashboardAvailableAssignments', response);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getInterpreterTotalInvoicePreview({commit}, {jobId, params}) {
        return new Promise((resolve, reject) => {
          fetchData.getInterInvoicePreview({jobId, params}).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      s_getTodayCalendarJobs({commit}, {date, view}) {
        return new Promise((resolve, reject) => {
          fetchData.getCalendarJobs(date, view).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('s_setTodayJobs', response);
            resolve(response);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      s_getTomorrowCalendarJobs({commit}, {date, view}) {
        return new Promise((resolve, reject) => {
          fetchData.getCalendarJobs(date, view).then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('s_setTomorrowJobs', response);
            resolve(response);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getFeedbackJobs({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getFeedbackJobs().then((response) => {
            if (response?.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setFeedbackJobs', response.jobs);
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    mutations: {
      setSDashboardBookedAssignments(state, data) {
        state.bookedAssignments.progress = false;
        Vue.set(state.bookedAssignments, 'data', data);
      },
      setSDashboardAvailableAssignments(state, data) {
        state.availableAssignments.progress = false;
        Vue.set(state.availableAssignments, 'data', data);
      },
      updateServerDashboardJobInfo(state, data) {
        const availableAssignmentsData = state.availableAssignments.data || {};
        const bookedAssignmentsData = state.bookedAssignments.data || {};
        const availableJobs = availableAssignmentsData.jobs || [];
        const bookedJobs = bookedAssignmentsData.jobs || [];

        if (availableJobs && availableJobs.length) {
          for (let i = 0; i < availableJobs.length; i += 1) {
            if (+availableJobs[i].id === +data.id) {
              availableJobs[i].status = data.status;
              if (data.status === 'accepted') {
                bookedJobs.length
                  ? Vue.set(state.bookedAssignments.data, 'jobs', bookedJobs.concat(availableJobs.splice(i, 1)))
                  : Vue.set(state.bookedAssignments.data, 'jobs', availableJobs.splice(i, 1));
              }
              if (data.status === 'abandoned' || data.status === 'hidden' || data.status === 'expired') {
                availableJobs.splice(i, 1);
              }
              return;
            }
          }
        }
        if (bookedJobs && bookedJobs.length) {
          for (let i = 0; i < bookedJobs.length; i += 1) {
            if (+bookedJobs[i].id === +data.id) {
              bookedJobs[i].status = data.status;
              if (data.status === 'hidden') {
                bookedJobs.splice(i, 1);
              }
              return;
            }
          }
        }
      },
      updateServerAvailableJobInfo(state, data) {
        const availableAssignmentsData = state.availableAssignments.data || {};
        const availableJobs = availableAssignmentsData.jobs || [];

        if (availableJobs && availableJobs.length) {
          for (let i = 0; i < availableJobs.length; i += 1) {
            if (+availableJobs[i].id === +data.id) {
              availableJobs[i].status = data.status;
              if (data.status === 'accepted' || data.status === 'abandoned' || data.status === 'hidden' || data.status === 'expired') {
                availableJobs.splice(i, 1);
              }
              return;
            }
          }
        }
      },
      s_setTodayJobs(state, response) {
        state.todayJobs = response;
      },
      s_setTomorrowJobs(state, response) {
        state.tomorrowJobs = response;
      },
      setFeedbackJobs(state, data) {
        state.feedbackJobs = data;
      },
      setNewInvitationFlag(state, status) {
        state.newInvitation = status;
      },
      clearFeedbackJobs(state) {
        state.feedbackJobs = '';
      },
      removeSAvailableAssignments(state) {
        state.availableAssignments.data = '';
      },
      removeSDashboardData(state) {
        state.todayJobs = '';
        state.tomorrowJobs = '';
        state.bookedAssignments.data = '';
        state.availableAssignments.data = '';
        state.feedbackJobs = '';
        state.bookedAssignments.progress = true;
        state.availableAssignments.progress = true;
      }
    }
  };
};
