import 'core-js';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import {cookieManager} from '@skiwo/sk-basic-components';
import webSocket from '@/push/web_socket';
import WSManager from '@/modules/ws_data_module';
import constants from '@/modules/constants';
import {createApp} from './ssr-app';

const subscribeToJobChannel = (route, vm) => {
  const tabInFocus = !document.hidden;
  if (route.meta && route.meta.viewers && route.params.id && vm.$webSocket && tabInFocus) {
    vm.$root.$jobChannel = vm.$webSocket.subscribe((data) => {
      // data contains the data sent by the backend
      WSManager.setDataFromJobChannel(vm, data);
    }, 'JobChannel', route.params.id);
  }
};

const backgroundConnection = (route, vm) => {
  if (route.meta && route.meta.viewers && route.params.id && vm.$webSocket) {
    vm.$root.$jobChannel.perform(
      'background'
    );
  }
};

const foregroundConnection = (route, vm) => {
  if (route.meta && route.meta.viewers && route.params.id && vm.$webSocket) {
    vm.$root.$jobChannel.perform(
      'foreground'
    );
  }
};
const unsubscribeFromJobChannel = (route, vm) => {
  if (route.meta && route.meta.viewers && route.params.id && vm.$root.$jobChannel && vm.$webSocket) {
    vm.$root.$jobChannel.unsubscribe();
  }
};

if (process
  && process.env
  && !['development', 'backend'].includes(process.env.NODE_ENV)) {
  Vue.config.productionTip = process.env.NODE_ENV != 'production';
}

Vue.mixin({
  methods: {
    setupCopyToClipboard() {
      const copyToClipboardHoverText = this.$gettext('Copy to clipboard');
      const copiedToClipboardHoverText = this.$gettext('Copied: %{copyText}');

      document.querySelectorAll('.js-copy-to-clipboard').forEach((selector) => {
        selector.classList.add('js-copy-to-clipboard--active');
        selector.setAttribute('data-content', copyToClipboardHoverText);

        const copyToClipboard = (text) => {
          const fakeElem = document.createElement('textarea');
          // Prevent zooming on iOS
          fakeElem.style.fontSize = '12pt';
          // Reset box model
          fakeElem.style.border = '0';
          fakeElem.style.padding = '0';
          fakeElem.style.margin = '0';
          // Move element out of screen horizontally
          fakeElem.style.position = 'absolute';
          fakeElem.style.right = '-9999px';
          // Move element to the same position vertically
          const yPosition = window.pageYOffset || document.documentElement.scrollTop;
          fakeElem.style.top = `${yPosition}px`;

          fakeElem.setAttribute('readonly', '');
          fakeElem.value = text;

          document.body.appendChild(fakeElem);
          fakeElem.select();
          document.execCommand('copy');
          document.body.removeChild(fakeElem);
        };

        const clickHandler = (event) => {
          const copyText = selector.innerText;

          event.preventDefault();
          copyToClipboard(copyText);
          selector.setAttribute('data-content', `${this.$gettextInterpolate(copiedToClipboardHoverText, {copyText})}`);
        };

        const outHandler = () => {
          selector.setAttribute('data-content', copyToClipboardHoverText);
        };

        selector.onclick = clickHandler;
        selector.onmouseout = outHandler;
      });
    }
  },
  beforeMount() {
    this.$moment.tz.setDefault(this.$store.getters['UserInfoStore/userServerTimeZoneName']);
    const {asyncData} = this.$options;
    if (asyncData) {
      // assign the fetch operation to a promise
      // so that in components we can do `this.dataPromise.then(...)` to
      // perform other tasks after data is ready
      this.dataPromise = asyncData({
        store: this.$store,
        route: this.$route,
        moment: this.$moment,
        fetcher: this.$fetcher
      });
    }
    if (this.dataPromise) {
      this.dataPromise.then((data) => {
        if (data && Array.isArray(data) && data.length) {
          for (const value of data) {
            if (Array.isArray(value)) {
              for (const val of value) {
                if (val == 'LOGOUT') {
                  this.$router.push(this.$makeRoute({name: 'SignIn'}));
                  return;
                }
              }
            }
            if (value == 'LOGOUT') {
              this.$router.push(this.$makeRoute({name: 'SignIn'}));
              return;
            }
          }
        }
        this.$store.commit('GlobalStore/stopProgress');
        this.setupCopyToClipboard();
      });
    }
  }
});
Vue.mixin({
  mounted() {
    const {asyncData} = this.$options;
    const userTime = this.$store.getters['UserInfoStore/userServerTimeZoneName'] == 'Etc/UTC' ? this.$moment().local() : this.$moment(); // set device local time on the Wizard instead of server default UTC time
    if (!this.$store.state.GlobalStore.timezoneSet && this.$store.state.TTAuthStore.loggedIn && !this.$store.state.TTAuthStore.godmode) {
      this.$store.dispatch('sendTimezone', userTime).then((data) => {
        if (data && !cookieManager.getCookie('locale') && this.$language.current !== data.locale) {
          // set $language.current for ie
          this.$language.current = data.locale;
        }
      });
    }
    if (asyncData) {
      if (this.dataPromise) {
        this.$store.commit('GlobalStore/startProgress');
      }
    }
    this.setupCopyToClipboard();
    if (this.$language.current === 'nb') {
      this.$moment.updateLocale('nb', {
        relativeTime: {
          future: 'i %s',
          past: '%s siden',
          s: 'noen få sekunder',
          ss: '%ds',
          m: 'ett minutt',
          mm: '%dm',
          h: 'en time',
          hh: '%dh',
          d: 'en dag',
          dd: '%dd',
          w: 'en uke',
          ww: '%d uker',
          M: 'a måned',
          MM: '%d måneder',
          y: 'a år',
          yy: '%d år'
        }
      });
    } else {
      this.$moment.updateLocale('en', {
        relativeTime: {
          future: 'in %s',
          past: '%s ago',
          s: 'a few seconds',
          ss: '%ds',
          m: 'a minute',
          mm: '%dm',
          h: 'an hour',
          hh: '%dh',
          d: 'a day',
          dd: '%dd',
          w: 'a week',
          ww: '%d weeks',
          M: 'a month',
          MM: '%d months',
          y: 'a year',
          yy: '%d years'
        }
      });
    }
  }
});

Vue.mixin({
  beforeRouteUpdate(to, from, next) {
    this.$store.commit('GlobalStore/remove404Page');
    const {asyncData} = this.$options;

    if (asyncData) {
      const res = asyncData({
        store: this.$store,
        route: to,
        moment: this.$moment
      });
      if (res) {
        this.$store.commit('GlobalStore/startProgress');
        res.then(() => {
          this.$store.commit('GlobalStore/stopProgress');
          next();
        }).catch(() => {
          return next;
        });
      } else {
        this.$store.commit('GlobalStore/stopProgress');
        next();
      }
    } else {
      next();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      document.onvisibilitychange = () => {
        // page tab opened in background
        if (document.hidden) {
          backgroundConnection(to, vm);
          return;
        } else {
          // page tab opened in foreground
          foregroundConnection(to, vm);
          return;
        }
      };
      subscribeToJobChannel(to, vm);
    });
  },
  beforeRouteLeave(to, from, next) {
    unsubscribeFromJobChannel(from, this);
    next();
  }
});

const {app, router, store} = createApp({webSocket, ssr: false});
if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
}
if (store.state.mobileApp !== cookieManager.getCookie('mobileApp')) {
  cookieManager.setCookie('mobileApp', store.state.mobileApp, {path: '/', expires: constants.COOKIE_EXPIRATION_TIME});
}
router.onReady(() => {
  app.$mount('#app');
});
