export default [{
  storeName: 'PostingStore/DateTimeStore',
  mutationName: 'setStartTime',
  recordValue: 'start time'
}, {
  storeName: 'PostingStore/DateTimeStore',
  mutationName: 'setFinishTime',
  recordValue: 'end time'
}, {
  storeName: 'PostingStore/LanguagesStore',
  mutationName: 'setLangFrom',
  recordValue: 'from language'
}, {
  storeName: 'PostingStore/LanguagesStore',
  mutationName: 'setLangTo',
  recordValue: 'to language'
}, {
  storeName: 'PostingStore/DateTimeStore',
  mutationName: 'setAssignmentStartDate',
  recordValue: 'select date'
}, {
  storeName: 'PostingStore/AssignmentTypeStore',
  mutationName: 'setAssignmentType',
  recordValue: 'type'
}, {
  storeName: 'PostingStore/BookerStore',
  mutationName: 'setBooker',
  recordValue: 'booker'
}, {
  storeName: 'PostingStore/DepartmentAttendersStore',
  mutationName: 'setAttenders',
  recordValue: 'attenders'
}, {
  storeName: 'PostingStore/DepartmentAttendersStore',
  mutationName: 'setDepartment',
  recordValue: 'department'
}, {
  storeName: 'PostingStore/PaymentMethodStore',
  mutationName: 'setPaymentMethod',
  recordValue: 'payment method'
}, {
  storeName: 'PostingStore/QualificationStore',
  mutationName: 'setQualification',
  recordValue: 'qualification level'
}, {
  storeName: 'PostingStore/GenderStore',
  mutationName: 'setGender',
  recordValue: 'gender'
}, {
  storeName: 'PostingStore/SubjectStore',
  mutationName: 'setCategory',
  recordValue: 'category'
}, {
  storeName: 'PostingStore/ParticipantsStore',
  mutationName: 'addParticipantPhones',
  recordValue: 'participant phones'
}, {
  storeName: 'PostingStore/ParticipantsStore',
  mutationName: 'removeParticipantPhone',
  recordValue: 'participant phones'
}, {
  storeName: 'PostingStore/ParticipantsStore',
  mutationName: 'removeInvitees',
  recordValue: 'invitees'
}, {
  storeName: 'PostingStore/ParticipantsStore',
  mutationName: 'addInvitees',
  recordValue: 'invitees'
}, {
  storeName: 'PostingStore/AssignmentTypeStore',
  mutationName: 'setFullAddress',
  recordValue: 'address'
}, {
  storeName: 'PostingStore/AssignmentTypeStore',
  mutationName: 'setAddressSaving',
  recordValue: 'save address info'
}, {
  storeName: 'PostingStore/AttachmentsStore',
  mutationName: 'setAttachments',
  recordValue: 'attachment'
}, {
  storeName: 'PostingStore/AttachmentsStore',
  mutationName: 'deleteAttachment',
  recordValue: 'attachment'
}, {
  storeName: 'PostingStore/DeadlineStore',
  mutationName: 'setDeadlineDate',
  recordValue: 'deadline date'
}, {
  storeName: 'PostingStore/DeadlineStore',
  mutationName: 'setDeadlineTime',
  recordValue: 'deadline time'
}, {
  storeName: 'OpenBookingStore',
  mutationName: 'setFirstName',
  recordValue: 'first name'
}, {
  storeName: 'OpenBookingStore',
  mutationName: 'setDepartment',
  recordValue: 'department'
}, {
  storeName: 'OpenBookingStore',
  mutationName: 'setPhoneCode',
  recordValue: 'phone code'
}];
