(<template>
  <form class="add-department__main-wrapper"
        @submit.prevent="checkForm">
    <sk-input :description="nameDescription"
              :preselected-value="departmentName"
              :margin-bottom="true"
              :placeholder="nameDefaultValue"
              :autofocus="true"
              :validation-name="departmentNameErrorsName"
              :reg-exp="/<\/?[^>]+(>|$)/g"
              class="add-department__field-name"
              @fieldvaluechanged="catchName" />
    <sk-input :description="emailDescription"
              :preselected-value="email"
              :margin-bottom="true"
              :placeholder="emailDefaultValue"
              :validation-name="emailErrorsName"
              @fieldvaluechanged="catchEmail" />
    <div class="add-department__tel-row">
      <sk-select :type="'countryCodes'"
                 :description="countryCodeText"
                 :default-value="countryCodeText"
                 :preselected-value="telCode"
                 :mob-numeric-keyboard="true"
                 class="add-department__code-select"
                 @csvaluechanged="catchTelCode" />
      <sk-input :description="phoneText"
                :preselected-value="telNumber"
                :margin-bottom="true"
                :placeholder="phoneText"
                :type="'tel'"
                :reg-exp="/[^0-9]/g"
                :mob-numeric-keyboard="true"
                :validation-name="phoneErrorsName"
                class="add-department__number-input"
                @fieldvaluechanged="catchTelNumber" />
    </div>
    <div v-if="addressesList.length"
         class="add-department__adding-field">
      <sk-multiselect :items-list="addressesList"
                      :description="addressesDescription"
                      :default-value="addressesDefaultValue"
                      :preselected-value="currentAddresses"
                      class="add-department__select"
                      @checkboxeschanged="catchAddresses" />
      <button class="sk-select-btn sk-select__address-btn"
              @click.prevent.stop="openAddAddressModal"></button>
    </div>
    <div v-if="employees.length"
         class="add-department__adding-field">
      <sk-multiselect :items-list="employeesList"
                      :description="employeesDescription"
                      :default-value="employeesDefaultValue"
                      :preselected-value="currentEmployees"
                      class="add-department__select"
                      @checkboxeschanged="catchEmployees" />
      <button class="sk-select-btn sk-select-btn__add-employee"
              @click.prevent.stop="openAddEmployeeModal()"></button>
    </div>
    <template v-if="userIsAdminOfEnterprise">
      <div v-if="currentPaymentMethods.length"
           class="add-department__adding-field">
        <sk-select :items-list="currentPaymentMethods"
                   :description="paymentMethodDescription"
                   :default-value="paymentMethodDefaultValue"
                   :validation-name="blacklistErrorsName"
                   :preselected-value="currentPaymentMethod"
                   class="add-department__select"
                   @csvaluechanged="catchPaymentMethod" />
        <button class="sk-select-btn"
                @click.prevent.stop="openAddPaymentModal"></button>
      </div>
    </template>
    <div class="add-department__info">
      <sk-note :text="bookingRefInfoText"
               class="add-department__info--note" />
      <sk-input :description="buyerDescription"
                :placeholder="buyerDescription"
                :preselected-value="defaultBookingReference"
                :disabled="disableBuyerReference"
                :reg-exp="/[^0-9a-zA-ZÆØÅæøå\/:\-\\ ]/g"
                :validation-name="referencesErrorsName"
                class="add-department__booking-ref"
                @fieldvaluechanged="catchBuyerRef" />
      <p v-if="isEmployeeHasRef"
         class="reference-hint"
         v-html="bookingRefHint"></p>
      <sk-input :description="orderDescription"
                :placeholder="orderDescription"
                :preselected-value="defaultPaymentBookingReference"
                :disabled="disableOrderReference"
                :reg-exp="/[^0-9a-zA-ZÆØÅæøå\/:\-\\ ]/g"
                :validation-name="paymentReferencesErrorsName"
                class="add-department__booking-ref"
                @fieldvaluechanged="catchOrderRef" />
      <p v-if="isEmployeeHasRef"
         class="reference-hint"
         v-html="bookingRefHint"></p>
    </div>
    <div class="add-department__submit-btns-cont">
      <button v-if="propDataCopy.fromModal"
              class="sk-btn sk-btn--default add-department__submit-btn"
              @click.prevent.stop="goBack">{{ backText }}</button>
      <button class="sk-btn sk-btn--default add-department__submit-btn">{{ submitBtnText }}</button>
    </div>
  </form>
</template>)

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        departmentName: this.data.currentDepartment ? this.data.currentDepartment.name || '' : '',
        allowToEditName: this.data.currentDepartment
          ? this.data.currentDepartment.id && this.data.currentDepartment.codeName !== ''
          : false,
        departmentNameErrors: [],
        departmentNameErrorsName: 'departmentNameError',
        defaultPaymentBookingReference: this.data.currentDepartment ? this.data.currentDepartment.defaultPaymentBookingReference || '' : '',
        defaultBookingReference: this.data.currentDepartment ? this.data.currentDepartment.defaultBookingReference || '' : '',
        email: this.data.currentDepartment ? this.data.currentDepartment.email || '' : '',
        emailErrors: [],
        phoneErrors: [],
        referencesErrorsName: 'referenceError',
        paymentReferencesErrorsName: 'paymentReferenceError',
        referencesErrors: [],
        paymentReferencesErrors: [],
        telCode: this.data.currentDepartment?.phoneCode || '+47',
        telNumber: this.data.currentDepartment?.phoneNumber || '',
        emailErrorsName: 'emailError',
        phoneErrorsName: 'phoneError',
        blacklistErrors: [],
        blacklistErrorsName: 'blacklistError',
        currentAddresses: this.data.currentDepartment?.addresses?.map((addr) => addr.id) || [],
        currentEmployees: [],
        currentPaymentMethod: this.data.currentDepartment?.paymentMethod?.id || '',
        newlyAddedDepartment: ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsAdminOfEnterprise',
      ]),
      ...mapGetters('PaymentPayoutStore', [
        'activePaymentMethods'
      ]),
      ...mapState('TTEnterpriseInfoStore', {
        lockBookingReference: (state) => state.companyInfo.lockBookingReference,
        lockPaymentBookingReference: (state) => state.companyInfo.lockPaymentBookingReference,
      }),
      ...mapState('EnterpriseStore', {
        addresses: ({addresses}) => addresses || [],
        employees: ({employees}) => employees || [],
        departments: ({departments}) => departments || []
      }),
      currentPaymentMethods() {
        return this.activePaymentMethods.map((paymentMethodData) => {
          paymentMethodData.name = helpers.getJobInfo.getPaymentMethodName(this, {
            paymentMethodData
          });

          return paymentMethodData;
        });
      },
      employeesList() {
        return this.employees.map((employee) => {
          return {
            id: employee.uid,
            name: `${employee.firstName || ''} ${employee.lastName || ''} ${employee.email || ''}`.trim()
          };
        });
      },
      initialEmployeesList() {
        const filteredEmployees = this.employees.filter((employee) => {
          return employee?.departments?.find((department) => {
            return department.id == this.departmentId;
          });
        }) || [];
        return filteredEmployees.map((employee) => employee.uid) || [];
      },
      nameDescription() { return this.$gettext('Department name*'); },
      nameDefaultValue() { return this.$gettext('Name*'); },
      buyerDescription() { return this.$store.getters['UserInfoStore/labelForBookingRef'] || this.$gettext('Buyer reference'); },
      orderDescription() { return this.$store.getters['UserInfoStore/labelForPaymentBookingRef'] || this.$gettext('Order number'); },
      emailDescription() { return this.$gettext('Department email'); },
      emailDefaultValue() { return this.$gettext('Email'); },
      countryCodeText() { return this.$gettext('Country code'); },
      phoneText() { return this.$gettext('Phone number'); },
      addressesDescription() { return this.$gettext('Address(es)'); },
      employeesDescription() { return this.$gettext('Employee(s)'); },
      addressesDefaultValue() { return this.$gettext('Select address(es)'); },
      employeesDefaultValue() { return this.$gettext('Select employee(s)'); },
      backText() { return this.$gettext('Go back'); },
      submitBtnText() { return this.data.currentDepartment ? this.$gettext('Edit') : this.$gettext('Add'); },
      departmentId() { return this.data.currentDepartment ? this.data.currentDepartment.id : ''; },
      paymentMethodDescription() { return this.$gettext('Default payment method'); },
      paymentMethodDefaultValue() { return this.$gettext('Select default payment method'); },
      bookingRefInfoText() { return this.$gettext('Determined by your company. If you are unsure, please contact the invoice manager in your company for which reference to use.'); },
      bookingRefHint() { return this.$gettext('ATTENTION! One or more employees in the selected department have provided their own fixed reference which overrides the department\'s reference when they register a new order'); },
      addressesList() {
        return this.addresses.map((address) => {
          return {
            id: address.id,
            name: helpers.getFullAddress(address)
          };
        });
      },
      isEmployeeHasRef() {
        return this.employees.some((obj) => this.currentEmployees.includes(obj.uid) && obj.paymentBookingReference);
      },
      propDataCopy() { return this.data; },
      disableBuyerReference() { return this.lockBookingReference && !this.$isGodmode() && this.data.currentDepartment.defaultPaymentBookingReference; },
      disableOrderReference() { return this.lockPaymentBookingReference && !this.$isGodmode() && this.data.currentDepartment.defaultBookingReference; },
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapActions('EnterpriseStore', [
        'createDepartment',
        'updateDepartment'
      ]),
      validateEmail(email) {
        return helpers.validation.validateEmail(email);
      },
      catchName(value) {
        this.departmentName = value;
      },
      catchEmail(value) {
        this.email = value;
      },
      catchTelCode(value) {
        this.telCode = value;
      },
      catchTelNumber(value) {
        this.telNumber = value;
      },
      catchBuyerRef(value) {
        this.defaultBookingReference = value;
      },
      catchOrderRef(value) {
        this.defaultPaymentBookingReference = value;
      },
      catchAddresses(data) {
        this.currentAddresses = data;
      },
      catchEmployees(data) {
        this.currentEmployees = data;
      },
      catchPaymentMethod(data) {
        this.currentPaymentMethod = data;
      },
      closeModal() {
        this.$emit('closemodal');
      },
      stopProgress() {
        this.$emit('stopprogress');
      },
      checkForm() {
        let isValidForm = true;

        this.emailErrors = [];
        this.departmentNameErrors = [];
        this.$store.commit('ModalStore/removeModalErrors');

        if (this.email.trim() && !this.validateEmail(this.email.trim())) {
          isValidForm = false;
          this.emailErrors.push(this.$gettext('Please enter a valid email'));
        }

        if (!this.departmentName.trim()) {
          isValidForm = false;
          this.departmentNameErrors.push(this.$gettext('Department name is required'));
        }

        this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.departmentNameErrorsName, errors: this.departmentNameErrors});

        if (isValidForm) {
          this.submitForm();
        }
      },
      submitForm() {
        const form = new FormData();
        const submitAction = this.departmentId ? 'updateDepartment' : 'createDepartment';
        const params = {form};

        form.append('department[name]', this.departmentName.trim());
        form.append('department[default_payment_booking_reference]', this.defaultPaymentBookingReference.trim());
        form.append('department[default_booking_reference]', this.defaultBookingReference.trim());
        form.append('department[email]', this.email.toLowerCase().trim());
        for (const addr of this.currentAddresses) {
          form.append('department[addresses][][id]', addr);
        }

        if (this.telCode && this.telNumber) {
          form.append('department[phone_code]', this.telCode);
          form.append('department[phone_number]', this.telNumber.trim());
        }

        if (this.departmentId) {
          params.departmentId = this.departmentId;
        }

        for (const employee of this.currentEmployees) {
          form.append('department[members][][uid]', employee);
        }

        if (this.currentPaymentMethod) {
          form.append('department[default_payment_method_id]', this.currentPaymentMethod);
        }

        this.$emit('startprogress');
        this[submitAction](params).then((response) => {
          // from other enterprise modals
          if (this.propDataCopy.fromModal) {
            this.newlyAddedDepartment = response.id;
            this.goBack();
          } else if (this.data.context.preselectDataAfterAddingDepartment) {
            this.closeModal();
            this.data.context.preselectDataAfterAddingDepartment(response.id);
          } else {
            if (this.data.context && this.data.context[this.data.successHandlingCallback]) {
              this.data.context[this.data.successHandlingCallback]();
            }
            this.trackEnterpriseGA();
            this.$store.dispatch('EnterpriseStore/getEmployees');
            this.$store.dispatch('PaymentPayoutStore/getPayments');
            this.closeModal();
          }
          this.$emit('stopprogress');
        }).catch((error) => {
          let isHandledError = false;
          this.$emit('stopprogress');
          const errors = error?.data?.errors;
          if (error && error?.status == 401) {
            Promise.all([this.$store.dispatch('UserInfoStore/fetchUserInfo', this.$store.getters['UserInfoStore/userUid']), this.$store.dispatch('EnterpriseStore/getDepartments')]).then(() => {
              this.$store.dispatch('ModalStore/closeModal');
              setTimeout(() => {
                this.$store.commit('InfoModalStore/setInfoModal', {
                  text: this.$gettext('Sorry, you don\'t have rights to manage departments. Please contact an administrator.')
                });
              }, 0);
            });
            return;
          }
          if (errors['global!']) {
            isHandledError = true;

            this.blacklistErrors = errors['global!'];
            this.$store.commit('ErrorsStore/setError', {name: this.blacklistErrorsName, errors: this.blacklistErrors});
          }
          if (errors?.default_booking_reference?.[0] === 'Cannot modify locked reference') {
            isHandledError = true;
            this.referencesErrors = [this.$gettext('Cannot modify locked reference')];
            this.$store.commit('ErrorsStore/setError', {name: this.referencesErrorsName, errors: this.referencesErrors});
          }
          if (errors?.default_payment_booking_reference?.[0] === 'Cannot modify locked reference') {
            isHandledError = true;
            this.paymentReferencesErrors = [this.$gettext('Cannot modify locked reference')];
            this.$store.commit('ErrorsStore/setError', {name: this.paymentReferencesErrorsName, errors: this.paymentReferencesErrors});
          }
          if (errors?.email?.includes('Invalid email')) {
            isHandledError = true;

            this.emailErrors = [this.$gettext('Please check the email address you have provided for spelling errors, and try again.')];
            this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});
          }
          if (errors?.phone_number?.includes('Phone number is not valid.')) {
            isHandledError = true;

            this.phoneErrors = [this.$gettext('Please check the phone number you provided for any errors and try again.')];
            this.$store.commit('ErrorsStore/setError', {name: this.phoneErrorsName, errors: this.phoneErrors});
          }
          if (errors?.name_can_be_changed?.includes('NAV department, name can\'t be changed')) {
            isHandledError = true;

            this.departmentNameErrors = [this.$gettext('This department is NAV related and its name can\'t be changed')];
            this.$store.commit('ErrorsStore/setError', {name: this.departmentNameErrorsName, errors: this.departmentNameErrors});
          }
          if (!isHandledError) {
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            }, 0);
          }
        });
      },
      trackEnterpriseGA() {
        this.departmentId
          ? this.$skGA.emitEvent(this.$GADataConstructor.getEnterpriseObjGA('department', 'edit'))
          : this.$skGA.emitEvent(this.$GADataConstructor.getEnterpriseObjGA('department', 'add'));
      },
      openAddAddressModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-address-modal',
          width: 410,
          data: {
            title: this.$gettext('Add new address'),
            context: this,
            successHandlingCallbackName: 'preselectDataAfterAddingAddress',
            currentDepartment: this.data.currentDepartment
          }
        });
      },
      openAddEmployeeModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-employee',
          width: 410,
          data: {
            title: this.$gettext('New employee'),
            context: this,
            currentDepartment: this.data.currentDepartment,
            successHandlingCallbackName: 'preselectDataAfterAddingMember'
          }
        });
      },
      openAddPaymentModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'payment-method-option',
          width: 510,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Payment Methods'),
            context: this,
            successHandlingCallback: 'preselectDataAfterAddingPayment'
          }
        });
      },
      goBack() {
        this.propDataCopy.componentData.departments.push(this.newlyAddedDepartment);
        this.$store.commit('ModalStore/setModal', {
          component: this.propDataCopy.fromModal.component,
          width: 410,
          data: {
            title: this.propDataCopy.fromModal.title,
            context: this,
            preservedData: this.propDataCopy.componentData
          }
        });
      },
      preselectDataAfterAddingMember(uid) {
        this.currentEmployees.push(uid);
        this.preselectData('successAddingMemberCallbackName');
      },
      preselectDataAfterAddingPayment(paymentMethodData = {}) {
        this.currentPaymentMethod = paymentMethodData.id;
        this.preselectData('successAddingPaymentCallbackName', paymentMethodData);
      },
      preselectDataAfterAddingAddress(addressId) {
        this.currentAddresses.push(addressId);
        this.preselectData('successAddingAddressCallbackName');
      },
      preselectData(callbackName, paymentMethodData) {
        if (this.data[callbackName] && this.data.context[this.data[callbackName]]) {
          const paymentMethod = paymentMethodData || {id: this.currentPaymentMethod};
          const currentDepartment = {
            ...this.data.currentDepartment,
            ...{
              name: this.departmentName,
              defaultPaymentBookingReference: this.defaultPaymentBookingReference,
              email: this.email,
              paymentMethod
            }
          };

          this.data.context[this.data[callbackName]]({
            currentDepartment,
            currentEmployees: this.currentEmployees,
            currentPaymentMethod: this.currentPaymentMethod,
            currentAddresses: this.currentAddresses
          });
        }
      }
    },
    mounted() {
      this.currentEmployees = this.data.currentEmployees || this.initialEmployeesList || [];
      this.currentPaymentMethod = this.currentPaymentMethod || '';
      this.currentAddresses = this.data.currentAddresses || this.currentAddresses || [];
    }
  };
</script>

<style>
  .add-department__code-select .sk-select__list {
    top: auto;
    bottom: 100%;
  }

  .add-department__select .sk-select__list {
    top: -1px;
    max-height: 200px;
    transform: translateY(-100%);
  }

  .add-department__booking-ref > .sk-input__input-wrapper {
    background-color: #fff;
  }
</style>

<style scoped>
.add-department__main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 17px 30px 30px;
}

.add-department__field-name {
  width: 215px;
}

.add-department__field-code {
  width: 115px;
}

.add-department__tel-row {
  display: flex;
  width: 100%;
}

.add-department__code-select {
  flex-shrink: 0;
  width: 85px;
  margin-right: 10px;
}

.add-department__number-input {
  width: calc(100% - 95px);
}

.add-department__adding-field {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.add-department__select {
  flex-grow: 1;
  width: calc(100% - 35px);
}

.add-department__submit-btns-cont {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.add-department__form {
  border: 1px solid #d3d5de;
}

.add-department__form-title {
  margin-top: 20px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.add-department__info {
  margin: 10px 0 15px 0;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--color-warning-200, #f0e8dd);
}

.add-department__info--note {
  margin: -8px -8px 0 -8px;
}

.add-department__booking-ref {
  width: auto;
  margin: 12px 0 8px 27px;
}

.reference-hint {
  margin-left: 27px;
  font-size: 12px;
}

.reference-hint--enterprise {
  margin-top: 10px;
}

.add-department__submit-btn {
  display: inline-block;
  width: auto;
  margin-left: 15px;
  padding: 0 25px;
}

.sk-select-btn {
  margin-top: 21px;
}

.sk-select__address-btn {
  margin-top: 21.6px;
}

@media (max-width: 767px) {
  .add-department__field-name {
    width: 100%;
  }

  .add-department__main-wrapper {
    padding: 15px;
  }

  .sk-select__address-btn,
  .sk-select-btn {
    margin-top: 19px;
  }

  .sk-select-btn__add-employee {
    margin-top: 20px;
  }
}
</style>
