(<template>
  <div class="payout-info">
    <div class="payout-info__steps-cont">
      <span v-for="step in steps"
            :key="step"
            :class="{'payout-info__step--active': step == currentStep}"
            class="payout-info__step"
            @click="goToStep(step)"></span>
    </div>
    <div v-for="component in filteredComponentsList"
         :key="component.name"
         class="payout-info__content-cont">
      <h1 class="payout-info__title">{{ component.title }}</h1>
      <component :is="component.name"
                 @gotonext="goToNext"
                 @gotoback="goToBack"
                 @closemodal="closeModal"
                 @startprogress="$emit('startprogress')"
                 @stopprogress="$emit('stopprogress')" />
    </div>
  </div>
</template>)

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import Step1 from './steps/Step1';
  import Step2 from './steps/Step2';
  import Step3UsingOurServices from './steps/Step3UsingOurServices';
  import Step3UsingOwnProvider from './steps/Step3UsingOwnProvider';

  export default {
    components: {
      'step-1': Step1,
      'step-2': Step2,
      'step-3-using-our-services': Step3UsingOurServices,
      'step-3-using-own-provider': Step3UsingOwnProvider
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        currentStep: 1,
        steps: [1, 2]
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      ...mapGetters('TTPayoutStore', ['getPayoutMethodType']),
      ...mapState('TTPayoutStore', {
        userHasCompany: ({payoutInfoWizard}) => payoutInfoWizard.userHasCompany,
        userHasOwnProvider: ({payoutInfoWizard}) => payoutInfoWizard.userHasOwnProvider
      }),
      componentsList() {
        return [{
          name: 'step-1',
          condition: this.currentStep === 1,
          title: this.$gettext('Register payout method')
        }, {
          name: 'step-2',
          condition: this.currentStep === 2 && this.userHasCompany,
          title: this.$gettext('I have my own company')
        }, {
          name: 'step-2',
          condition: this.currentStep === 2 && !this.userHasCompany,
          title: this.$gettext('I do not have my own business/company.')
        }, {
          name: 'step-3-using-our-services',
          condition: this.currentStep === 3 && !this.userHasOwnProvider,
          title: this.userHasCompany
            ? this.$gettext('Great! Please add your company information')
            : this.$gettextInterpolate(this.$gettext('Sign up for %{domain}\'s Payroll services'), {domain: this.$getString('domainData', 'platformName')})
        }, {
          name: 'step-3-using-own-provider',
          condition: this.currentStep === 3 && this.userHasOwnProvider,
          title: this.userHasCompany
            ? this.$gettext('Ok, just remember that by using your own provider you will be required to: ')
            : this.$gettextInterpolate(this.$gettext('We have noted that you will send invoices to %{domain} on your own'), {domain: this.$getString('domainData', 'platformName')})
        }];
      },
      filteredComponentsList() {
        return this.componentsList.filter(({condition}) => condition);
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapActions('TTPayoutStore', [
        'postPayoutMethod',
        'setError'
      ]),
      closeModal() {
        this.$emit('closemodal');
      },
      setCurrentStep(value) {
        this.currentStep = value;
      },
      goToStep(step) {
        if (step < this.currentStep) this.setCurrentStep(step);
        this.$store.commit('TTPayoutStore/clearStore');
      },
      goToNext() {
        const nextStep = this.currentStep + 1;
        if (this.steps.includes(nextStep)) {
          this.setCurrentStep(nextStep);
        } else {
          this.submitForm();
        }
      },
      goToBack() {
        const prevStep = this.currentStep -= 1;
        if (this.steps.includes(prevStep)) {
          this.setCurrentStep(prevStep);
        }
      },
      submitForm() {
        this.$emit('startprogress');
        this.postPayoutMethod().then((payout) => {
          if (!payout.default) {
            this.$store.dispatch('PaymentPayoutStore/setDefaultPayoutMethod', {
              id: payout.id,
              type: this.getPayoutMethodType
            }).then(() => {
              this.fetchUserInfo();
            }).catch(() => {
              this.fetchUserInfo();
            });
          } else {
            this.fetchUserInfo();
          }
        }).catch((error) => {
          this.$emit('stopprogress');
          const organizationNumberErrors = error.errors ? error.errors.organizationNumber || error.errors.org_number || [] : [];
          if (organizationNumberErrors.length && organizationNumberErrors.includes('Organisation number is invalid')) {
            this.setError({
              fieldName: 'organizationNumber',
              errorText: this.$gettext('Organization number is invalid.')
            });
          } else {
            this.closeModal();
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            });
          }
        });
      },
      fetchUserInfo() {
        this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
          this.$store.dispatch('EditProfileStore/getUserProfile', {id: this.userUid}).then(() => {
            this.$emit('stopprogress');
            this.closeModal();
            if (this.data.callback && this.data.context) {
              setTimeout(() => {
                this.data.context[this.data.callback]();
              });
            }
          });
        });
      }
    },
    beforeMount() {
      const storeData = this.$store.state.EditProfileStore.profileData;
      if (!storeData) {
        this.$store.commit('ModalStore/startModalProgress');
        this.$store.dispatch('EditProfileStore/getUserProfile', {id: this.userUid}).then(() => {
          this.$store.commit('ModalStore/stopModalProgress');
        });
      }
    },
    beforeDestroy() {
      this.$store.commit('TTPayoutStore/clearStore');
    }
  };
</script>

<style scoped>
  .payout-info {
    display: flex;
    flex-direction: column;
  }

  .payout-info__steps-cont {
    display: flex;
    justify-content: center;
    padding: 15px;
  }

  .payout-info__step {
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #d3d5de;
  }

  .payout-info__step--active {
    background-color: #ff5b24;
    background-color: var(--color-secondary);
  }

  .payout-info__content-cont {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px 30px;
  }

  .payout-info__title {
    margin-bottom: 8px;
    font-size: 20px;
  }
</style>
