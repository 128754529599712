import {cookieManager} from '@skiwo/sk-basic-components';
import constants from '@/modules/constants';

const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

const refreshUserInfoCookies = (userInfo) => {
  if (isBrowser()) {
    cookieManager.setCookie('userInfo', JSON.stringify(userInfo), {path: '/', expires: constants.COOKIE_EXPIRATION_TIME});
    // clear unnecessary cookie value
    cookieManager.setCookie('enterprise', '', {path: '/', expires: -1});
  }
};

export default ({$fetcher: fetchData, $moment: moment}) => {
  return {
    namespaced: true,
    state: {
      mainUserInfo: {
        createdAt: '',
        locale: '',
        role: '',
        firstName: '',
        lastName: '',
        timeZone: 'Europe/Oslo',
        timestamp: '',
        avatar: '',
        email: '',
        emailVerified: false,
        emailVerificationRequired: false,
        emailWhitelisted: false,
        uid: '',
        currency: {
          id: 1,
          name: 'NOK'
        },
        jobEntity: '',
        jobEntityId: '',
        phone: {
          code: '',
          number: '',
          verified: false
        },
        alternativePhone: {
          code: '',
          number: '',
          verified: false
        },
        currentProvider: '',
        wizard: '',
        forEnterprise: '',
        enterpriseId: '',
        seller: false,
        interpreter: true
      },
      additionalUserInfo: {
        id: '',
        updated: false,
        userBookedInterpretationJobs: false,
        userBookedTranslationJobs: false,
        cooperationAgreementSigned: true,
        externalPlatformName: '',
        externalPlatformLink: '',
        allowQuickBook: '',
        allowForHotline: '',
        allowedSessionTypes: [],
        showWelcomePopup: false,
        showDrivingPopup: false,
        advertisementShownAt: '',
        privacyPopup: false,
        allowBooking: false,
        enterprise: {},
        defaultLangTo: '',
        defaultBookingReference: '',
        favouriteInterLang: '',
        onlineInterLang: '',
        usedPolitiAttestedOption: false,
        defaultPayoutMethod: '',
        supplierNumber: '',
        sessionTypeStatus: '',
        accountStatus: '',
        blockedForEnterprises: '',
        showStatisticsPage: false,
        isShared: false,
        canStandBy: false,
        accountStatusExpiresAt: '',
      },
      adminInfo: {
        uid: '',
        firstName: '',
        lastName: '',
        email: ''
      },
    },
    getters: {
      mainUserInfo(state) { return state.mainUserInfo; },
      additionalUserInfo(state) { return state.additionalUserInfo; },
      welcomeModal(state) { return state.additionalUserInfo.showWelcomePopup; },
      drivingLicenseModal(state) { return state.additionalUserInfo.showDrivingPopup; },
      showAdvertisement(state, getters) {
        if (!getters.enterpriseSettings.showTranslationsAdvertisement) return false;
        return state.additionalUserInfo.advertisementShownAt
          ? moment().subtract('1', 'month').isAfter(moment(state.additionalUserInfo.advertisementShownAt))
          : !state.additionalUserInfo.advertisementShownAt;
      },
      showOriginCountrySelectOnPosting() { return false; },
      // =================================================================================================================
      // ADMIN INFO
      // =================================================================================================================
      adminFirstName(state) { return state.adminInfo.firstName; },
      adminLastName(state) { return state.adminInfo.lastName; },
      adminUid(state) { return state.adminInfo.uid; },
      // =================================================================================================================
      // MAIN USER INFO
      // =================================================================================================================
      userCreatedAt(state) { return state.mainUserInfo.createdAt; },
      userUid(state) { return state.mainUserInfo.uid; },
      userEntity(state) { return state.mainUserInfo.jobEntity; },
      userEntityId(state) { return state.mainUserInfo.jobEntityId; },
      userAvatar(state) { return state.mainUserInfo.avatar || ''; },
      userFirstName(state) { return state.mainUserInfo.firstName; },
      userLastName(state) { return state.mainUserInfo.lastName; },
      fullName(state) { return `${state.mainUserInfo.firstName} ${state.mainUserInfo.lastName}`; },
      userEmail(state) { return state.mainUserInfo.email || ''; },
      isNAVUser(state, getters) { return getters.userEmail ? /@nav.no$/.test(getters.userEmail) : false; },
      userHasEmail(state) { return state.mainUserInfo.email !== '' && state.mainUserInfo.email !== undefined; },
      isEmailVerificationRequired(state) { return state.mainUserInfo.emailVerificationRequired; },
      isEmailVerified(state, getters) { return getters.userHasEmail && state.mainUserInfo.emailVerified; },
      userHasPhone(state) { return (state.mainUserInfo.phone && state.mainUserInfo.phone.code && state.mainUserInfo.phone.number) || ''; },
      userHasAlternativePhone(state) { return (state.mainUserInfo.alternativePhone && state.mainUserInfo.alternativePhone.code && state.mainUserInfo.alternativePhone.number) || ''; },
      isPhoneVerified(state, getters) { return getters.userHasPhone && state.mainUserInfo.phone.verified; },
      isalternativePhoneVerified(state, getters) { return getters.userHasAlternativePhone && state.mainUserInfo.alternativePhone.verified; },
      userPhone(state, getters) { return getters.userHasPhone ? state.mainUserInfo.phone : {}; },
      userAlternativePhone(state, getters) { return getters.userHasAlternativePhone ? state.mainUserInfo.alternativePhone : {}; },
      userFullPhone(state, getters) { return getters.userHasPhone ? `${getters.userPhone.code}${getters.userPhone.number}` : ''; },
      userFullAlternativePhone(getters) { return getters.userHasAlternativePhone ? `${getters.userAlternativePhone.code}${getters.userAlternativePhone.nimber}` : ''; },
      currentRole(state) { return state.mainUserInfo.role; },
      currentProvider(state) { return state.mainUserInfo.currentProvider; },
      userServerTimestamp(state) { return state.mainUserInfo.timestamp; },
      userServerTimeZoneName(state) { return state.mainUserInfo.timeZone; },
      userCurrency(state) { return state.mainUserInfo.currency; },
      userCurrencyName(state, getters) { return getters.userCurrency.name; },
      userCurrencyId(state, getters) { return getters.userCurrency.id; },
      userLocale(state) { return state.mainUserInfo.locale; },
      // =================================================================================================================
      // ADDITIONAL USER INFO
      // =================================================================================================================
      userId(state) { return state.additionalUserInfo.id; },
      oldPlatformLink(state) { return state.additionalUserInfo.externalPlatformLink; },
      userLangTo(state) { return state.additionalUserInfo.defaultLangTo; },
      langFavouriteInters(state, getters) { return state.additionalUserInfo.favouriteInterLang || getters.userLangTo || 1; },
      defaultBookingReference(state) { return state.additionalUserInfo.defaultBookingReference; },
      defaultJobDuration() { return 60; },
      maxShortNoticeCancellation() { return 24; },
      usedPolitiAttestedOption(state) { return state.additionalUserInfo.usedPolitiAttestedOption; },
      supplierNumber(state) { return state.additionalUserInfo.supplierNumber; },
      isShared(state) { return state.additionalUserInfo.isShared; },
      accountStatusExpiresAt(state) { return state.additionalUserInfo.accountStatusExpiresAt; },
      accountStatus(state) { return state.additionalUserInfo.accountStatus; },
      useHasInstalledMobileApp(state) { return !!state.additionalUserInfo?.mobileApp?.registeredPlatforms.length; },
      // =================================================================================================================
      // PAYOUT METHODS
      // =================================================================================================================
      defaultPayoutMethod(state) { return state.additionalUserInfo.defaultPayoutMethod; },
      userIsPayrollInterpreter(state, getters) { return getters.userIsInterpreter ? getters.defaultPayoutMethod === 'payroll' : false; },
      userIsDIPPSIndividualInterpreter(state, getters) { return getters.userIsInterpreter ? getters.defaultPayoutMethod === 'dipps_individual' : false; },
      userIsDIPPSOrgInterpreter(state, getters) { return getters.userIsInterpreter ? getters.defaultPayoutMethod === 'dipps_organization' : false; },
      userIsSelfIndividualInterpreter(state, getters) { return getters.userIsInterpreter ? getters.defaultPayoutMethod === 'self_service_individual' : false; },
      userIsSelfOrgInterpreter(state, getters) { return getters.userIsInterpreter ? getters.defaultPayoutMethod === 'self_service_organization' : false; },
      userIsOrgInterpreter(state, getters) { return getters.userIsDIPPSOrgInterpreter || getters.userIsSelfOrgInterpreter; },
      // =================================================================================================================
      // ROLES
      // =================================================================================================================
      userIsInterpreter(state) { return state.mainUserInfo.role === 'server'; },
      userIsNotInterpreter(state) { return state.mainUserInfo.role === 'buyer' || state.mainUserInfo.role === 'employee'; },
      // New Role under the server. Determines Translation Privileges
      userIsSeller(state) { return state.mainUserInfo.seller || false; },
      // if user has interpretation roles true | false
      userHasInterpretation(state) { return state.mainUserInfo.interpreter || true; },
      // SASS manager roles
      userIsManager(state) { return state.mainUserInfo.role === 'manager'; },
      userIsBusiness(state) { return state.mainUserInfo.role === 'buyer'; },
      userIsNotBusiness(state) { return state.mainUserInfo.role !== 'buyer'; },
      userIsEnterpriseMember(state) { return state.mainUserInfo.role === 'employee'; },
      userIsNotEnterpriseMember(state, getters) { return getters.currentRole !== 'employee'; },
      // =================================================================================================================
      // ENTERPRISE INFO
      // =================================================================================================================
      wholeEnterpriseInfo(state, getters) { return getters.userIsEnterpriseMember ? state.additionalUserInfo.enterprise || {} : {}; },
      enterpriseId(state, getters) { return getters.userIsEnterpriseMember ? getters.mainUserInfo.enterpriseId || getters.wholeEnterpriseInfo.id : ''; },
      kardemommeEnterprise(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseId === 248 || getters.enterpriseId === 4426 : false; },
      enterpriseName(state, getters) { return getters.userIsEnterpriseMember ? getters.wholeEnterpriseInfo.name || getters.enterpriseOrgNumber : ''; },
      enterpriseOrgNumber(state, getters) { return getters.userIsEnterpriseMember ? getters.wholeEnterpriseInfo.orgNumber || '' : ''; },
      enterpriseMemberBookingRef(state, getters) { return getters.userIsEnterpriseMember ? getters.wholeEnterpriseInfo.member?.bookingReference : ''; },
      enterpriseLogo(state, getters) { return getters.userIsEnterpriseMember ? getters.wholeEnterpriseInfo.logo : ''; },
      enterpriseRoles(state, getters) { return getters.userIsEnterpriseMember ? getters.wholeEnterpriseInfo.member?.roles || [] : []; },
      enterpriseMemberJobsScope(state, getters) { return getters.userIsEnterpriseMember ? getters.wholeEnterpriseInfo.member?.jobsScope || '' : ''; },
      // =================================================================================================================
      // ENTERPRISE SETTINGS
      // =================================================================================================================
      userEnterpriseName(state, getters) { return getters.userIsEnterpriseMember ? getters.wholeEnterpriseInfo.name : ''; },
      enterpriseSettings(state, getters) { return getters.userIsEnterpriseMember ? getters.wholeEnterpriseInfo.settings || {} : []; },
      enterpriseDefaultBookingReference(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.defaultBookingReference || '' : ''; },
      enterpriseDefaultPaymentBookingReference(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.defaultPaymentBookingReference || '' : ''; },
      userIsPoliceEnterprise(state, getters) { return getters.enterpriseSettings.isPolice || false; },
      userHasClientName(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.allowCliendName : false; },
      userHasClientId(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.allowCliendId : false; },
      userHasAutoApprove(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.autoApprove : false; },
      userAutoApproveSessionTypes(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.autoApproveSessionTypes || [] : []; },
      userCanUseChat(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.allowChat : true; },
      userCanUseQuickBook(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.allowQuickBook : state.additionalUserInfo.allowQuickBook; },
      userDefaultSessionType(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.defaultSessionType : 'phone'; },
      userAssignmentTypeDuration(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.assignmentTypeDuration || [] : []; },
      userDefaultQualificationLevel(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.defaultQualificationLevel || '' : ''; },
      userAllowedSessionTypes(state, getters) { return getters.userHasAllowedSessionTypes ? getters.enterpriseSettings.allowedSessionTypes || state.additionalUserInfo.allowedSessionTypes : []; },
      userCustomWarning(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.chatWarning : ''; },
      userHasAllowedSessionTypes(state, getters) {
        if (getters.userIsEnterpriseMember) {
          return !!getters.enterpriseSettings.allowedSessionTypes;
        } else if (getters.userIsBusiness) {
          return !!state.additionalUserInfo.allowedSessionTypes;
        } else {
          return false;
        }
      },
      userDefaultInterpreterGender(state, getters) {
        return getters.userIsEnterpriseMember
          ? getters.enterpriseSettings.defaultInterpreterGender === 0
            ? getters.enterpriseSettings.defaultInterpreterGender : ''
          : '';
      },
      allowInterpretationServices(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.allowInterpretationServices : false; },
      allowNewTranslationServices(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.allowNewTranslationServices : false; },
      allowQualificationPreference(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.allowQualificationPreference : true; },
      allowToEditBookingRefOnPosting(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.allowToEditBookingReferenceOnPosting : false; },
      allowToEditPaymentBookingRefOnPosting(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.allowToEditPaymentBookingReferenceOnPosting : false; },
      showOnlyOwnDepartmentsOnPosting(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.showOnlyOwnDepartmentsOnPosting : false; },
      showEnterpriseOrdersToAllMembers(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.showEnterpriseOrdersToAllMembers : false; },
      showTicketStatistics(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.showTicketStatisticsPage : false; },
      showInvoicesPage(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.showInvoicesPage : false; },
      showPowerBIPage(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.showPowerBiPage : true; },
      showJobStatistics(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.showJobStatisticsV2 : false; },
      defaultStandByValue(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.allowStandby || true : false; },
      userCanShowStaffingButton(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.showStaffingButton : false; },
      userCanShowFakeBookingFeatures(state, getters) { return getters.userIsEnterpriseMember ? !!getters.enterpriseSettings.hospitalContractFeaturesEnabled : false; },
      userCanShowSeriesOrderFeature(state, getters) { return getters.userIsEnterpriseMember ? !!getters.enterpriseSettings.seriesOrderEnabled : false; },
      userCanShowAlternativeLanguageFeature(state, getters) { return getters.userIsEnterpriseMember ? !!getters.enterpriseSettings.alternativeLanguageEnabled : false; },
      userCanShowSecretaryAssignmentFeature(state, getters) { return getters.userIsEnterpriseMember ? !!getters.enterpriseSettings.secretaryAssignmentEnabled : false; },
      showSendByPostTranslation(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.translationSendByPostAllowed : true; },
      showSendByPostPayableTranslation(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.translationSendByPostPayable : true; },
      translationCoverLetterRequired(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.translationCoverLetterRequired : false; },
      userHasRequireOrderAttachment(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.translationInitialAttachmentsRequired : false; },
      showSellerEmail(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.showSellerEmail : false; },
      // =================================================================================================================
      // DEFAULT PAYMENT METHOD INFO
      // =================================================================================================================
      defaultPaymentMethod(state, getters) { return getters.wholeEnterpriseInfo.defaultPaymentMethod; },
      labelForBookingRef(state, getters) {
        if (getters.userIsEnterpriseMember && getters.defaultPaymentMethod) {
          return getters.defaultPaymentMethod.labelForBookingReference || '';
        }
        return '';
      },
      labelForPaymentBookingRef(state, getters) {
        if (getters.userIsEnterpriseMember && getters.defaultPaymentMethod) {
          return getters.defaultPaymentMethod.labelForPaymentBookingReference || '';
        }
        return '';
      },
      labelForCaseNumber(state, getters) {
        if (getters.userIsEnterpriseMember && getters.defaultPaymentMethod) {
          return getters.defaultPaymentMethod.labelForCaseNumber || '';
        }
        return '';
      },
      userHasCaseNumber(state, getters) { return getters.userIsEnterpriseMember ? getters.defaultPaymentMethod.allowCaseNumber : false; },
      userHasPaymentBookingRef(state, getters) { return getters.userIsEnterpriseMember ? getters.defaultPaymentMethod.allowPaymentBookingReference : false; },
      userHasRequireCaseNumber(state, getters) { return getters.userIsEnterpriseMember ? getters.defaultPaymentMethod.requireCaseNumber : false; },
      userHasRequirePaymentBookingRef(state, getters) { return getters.userIsEnterpriseMember ? getters.defaultPaymentMethod.requirePaymentBookingReference : false; },
      userHasRequireBookingRef(state, getters) { return getters.userIsEnterpriseMember ? getters.defaultPaymentMethod.requireBookingReference : false; },
      userHasBookingRef(state, getters) {
        if (getters.userIsEnterpriseMember) {
          return !!getters.defaultPaymentMethod.allowBookingReference;
        } else if (getters.isNAVUser) {
          return true;
        }
        return false;
      },
      // =================================================================================================================
      // RIGHTS
      // =================================================================================================================
      userCanStandBy(state, getters) { return getters.userIsInterpreter ? state.additionalUserInfo.canStandBy : false; },
      userCanSeeHotline() { return false; },
      userCanSendMsgs(state, getters) { return getters.userCanManageJob || getters.userIsInterpreter; },
      userCanRegisterJobs(state, getters) { return getters.userIsInterpreter ? state.additionalUserInfo.allowBooking : false; },
      userCanSeePrices(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseSettings.showPrices : true; },
      userIsAdminOfEnterprise(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseRoles.includes('admin') : true; },
      userIsFinanceManager(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseRoles.includes('finance') : true; },
      userIsBooker(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseRoles.includes('organizer') : true; },
      userIsAttender(state, getters) { return getters.userIsEnterpriseMember ? getters.enterpriseRoles.includes('attender') : true; },
      userCanManageJob(state, getters) {
        if (getters.userIsEnterpriseMember) {
          return getters.userIsAdminOfEnterprise
                 || getters.userIsBooker
                 || getters.userIsFinanceManager
                 || getters.userIsAttender;
        }
        return getters.userIsBusiness;
      },
      userCanCreateDirectJobs(state, getters) {
        // for now direct booking allowed for the same roles, but it can be changed
        return getters.userCanCreateJobs;
      },
      userCanCreateJobs(state, getters) {
        if (getters.userIsNotInterpreter) {
          return getters.userIsEnterpriseMember
            ? getters.enterpriseSettings.allBookers || getters.userIsBooker || getters.userIsFinanceManager || getters.userIsAdminOfEnterprise
            : true;
        } else {
          return false;
        }
      },
      userCanShowStatistics(state, getters) {
        return getters.userIsNotInterpreter ? getters.userIsAdminOfEnterprise && state.additionalUserInfo.showStatisticsPage : false;
      }
    },
    actions: {
      sendWelcome({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.sendWelcome().then(() => {
            commit('setWelcome');
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendDrivingLicense({commit, getters}) {
        return new Promise((resolve, reject) => {
          fetchData.sendDrivingLicense(getters.userUid).then(() => {
            commit('setDrivingLicense');
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendPrivacyLevel({commit, getters}, form) {
        return new Promise((resolve, reject) => {
          fetchData.updateSettings(getters.userUid, form).then(() => {
            commit('setPrivacyLevel');
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      sendAdvertisementShownAt({commit, getters}) {
        return new Promise((resolve, reject) => {
          fetchData.sendAdvertisementShownAt(getters.enterpriseId, getters.userUid).then((data) => {
            commit('setAdvertisementShownAt', {advertisementShownAt: data.advertisementShownAt});
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      updateJobsScope({commit, getters}, value) {
        const params = {
          uid: getters.userUid,
          jobs_scope: value
        };
        return new Promise((resolve, reject) => {
          fetchData.updateJobsScope(getters.enterpriseId, getters.userUid, params).then((employeeInfoObj) => {
            if (employeeInfoObj.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setEmployeeInfo', employeeInfoObj.employee);
            resolve(employeeInfoObj.employee);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      fetchUserInfo({commit}, uid) {
        return new Promise((resolve, reject) => {
          fetchData.getUserInfo(uid).then((userInfoObj) => {
            if (userInfoObj.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setUserInfo', {userInfoObj: userInfoObj.person || {}});
            resolve(userInfoObj.person);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      setNamesAndAvatar({commit}, {form, firstName, lastName}) {
        return new Promise((resolve, reject) => {
          fetchData.sendNamesAndAvatar(form).then((data) => {
            commit('setUserInfo', {
              userInfoObj: {
                firstName,
                lastName,
                avatar: data.avatar
              }
            });
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      setMainUserInfo({state, commit}, {userInfoObj}) {
        commit('setUserInfo', {userInfoObj});
        state.additionalUserInfo.updated = false;
      }
    },
    mutations: {
      setUserInfo(state, {userInfoObj}) {
        // MAIN INFO
        if (userInfoObj.role !== undefined) {
          state.mainUserInfo.role = userInfoObj.role;
        }
        if (userInfoObj.seller !== undefined) {
          state.mainUserInfo.seller = userInfoObj.seller;
        }
        if (userInfoObj.interpreter !== undefined) {
          state.mainUserInfo.interpreter = userInfoObj.interpreter;
        }
        if (userInfoObj.timeZone !== undefined) {
          state.mainUserInfo.timeZone = userInfoObj.timeZone;
        }
        if (userInfoObj.firstName !== undefined) {
          state.mainUserInfo.firstName = userInfoObj.firstName;
        }
        if (userInfoObj.lastName !== undefined) {
          state.mainUserInfo.lastName = userInfoObj.lastName;
        }
        if (userInfoObj.avatar !== undefined) {
          state.mainUserInfo.avatar = userInfoObj.avatar;
        }
        if (userInfoObj.email !== undefined) {
          state.mainUserInfo.email = userInfoObj.email;
        }
        if (userInfoObj.emailVerified !== undefined) {
          state.mainUserInfo.emailVerified = userInfoObj.emailVerified;
        }
        if (userInfoObj.emailVerificationRequired !== undefined) {
          state.mainUserInfo.emailVerificationRequired = userInfoObj.emailVerificationRequired;
        }
        if (userInfoObj.emailWhitelisted !== undefined) {
          state.mainUserInfo.emailWhitelisted = userInfoObj.emailWhitelisted;
        }
        if (userInfoObj.createdAt !== undefined) {
          state.mainUserInfo.createdAt = userInfoObj.createdAt;
        }
        if (userInfoObj.uid !== undefined) {
          state.mainUserInfo.uid = userInfoObj.uid;
        }
        if (userInfoObj.jobEntity !== undefined) {
          state.mainUserInfo.jobEntity = userInfoObj.jobEntity;
        }
        if (userInfoObj.jobEntityId !== undefined) {
          state.mainUserInfo.jobEntityId = userInfoObj.jobEntityId;
        }
        if (userInfoObj.wizard !== undefined) {
          state.mainUserInfo.wizard = userInfoObj.wizard;
        }
        if (userInfoObj.phone) {
          state.mainUserInfo.phone = JSON.parse(JSON.stringify(userInfoObj.phone));
        }
        if (userInfoObj.phoneVerified) {
          state.mainUserInfo.phone.verified = true;
        }
        if (userInfoObj.locale) {
          state.mainUserInfo.locale = userInfoObj.locale;
          if (isBrowser()) {
            const cookieParams = {path: '/', expires: constants.COOKIE_EXPIRATION_TIME};
            if (window.location.hostname !== 'localhost') {
              // ie reject cookies with domain: 'localhost'
              cookieParams.domain = (() => {
                if (window.location.hostname.includes('herokuapp.com')) return window.location.hostname;
                if (window.location.hostname.includes('salita.no')) return '.salita.no';
                return constants.COOKIE_PATH || '.tikktalk.com';
              })();
            }
            cookieManager.setCookie('locale', userInfoObj.locale, cookieParams);
          }
        }
        if (userInfoObj.currentProvider) {
          state.mainUserInfo.currentProvider = userInfoObj.currentProvider;
        }
        if (userInfoObj.forEnterprise !== undefined) {
          state.mainUserInfo.forEnterprise = userInfoObj.forEnterprise;
        }
        // ADDITIONAL INFO
        state.additionalUserInfo.updated = true;
        if (userInfoObj.personId !== undefined) {
          state.additionalUserInfo.id = userInfoObj.personId;
        }
        if (userInfoObj.cooperationAgreementSigned !== undefined) {
          state.additionalUserInfo.cooperationAgreementSigned = userInfoObj.cooperationAgreementSigned;
        }
        if (userInfoObj.old_platform) {
          state.additionalUserInfo.externalPlatformName = userInfoObj.old_platform.name;
          state.additionalUserInfo.externalPlatformLink = userInfoObj.old_platform.link;
        }
        if (userInfoObj.userBookedTranslationJobs) {
          state.additionalUserInfo.userBookedTranslationJobs = userInfoObj.userBookedTranslationJobs;
        }
        if (userInfoObj.userBookedInterpretationJobs) {
          state.additionalUserInfo.userBookedInterpretationJobs = userInfoObj.userBookedInterpretationJobs;
        }
        if (userInfoObj.showWelcomePopup !== undefined) {
          state.additionalUserInfo.showWelcomePopup = userInfoObj.showWelcomePopup;
        }
        if (userInfoObj.advertisementShownAt !== undefined) {
          state.additionalUserInfo.advertisementShownAt = userInfoObj.advertisementShownAt;
        }
        if (userInfoObj.showDrivingPopup !== undefined) {
          state.additionalUserInfo.showDrivingPopup = userInfoObj.showDrivingPopup;
        }
        if (userInfoObj.privacyPopup !== undefined) {
          state.additionalUserInfo.privacyPopup = userInfoObj.privacyPopup;
        }
        if (userInfoObj.timestamp !== undefined) {
          state.additionalUserInfo.timestamp = userInfoObj.timestamp;
        }
        if (userInfoObj.allowQuickBook !== undefined) {
          state.additionalUserInfo.allowQuickBook = userInfoObj.allowQuickBook;
        }
        if (userInfoObj.defaultBookingReference !== undefined) {
          state.additionalUserInfo.defaultBookingReference = userInfoObj.defaultBookingReference;
        }
        if (userInfoObj.allowBooking !== undefined) {
          state.additionalUserInfo.allowBooking = userInfoObj.allowBooking;
        }
        if (userInfoObj.allowedSessionTypes !== undefined) {
          state.additionalUserInfo.allowedSessionTypes = userInfoObj.allowedSessionTypes;
        }
        if (userInfoObj.mobileApp !== undefined) {
          state.additionalUserInfo.mobileApp = userInfoObj.mobileApp;
        }
        if (userInfoObj.enterpriseId !== undefined) {
          state.mainUserInfo.enterpriseId = userInfoObj.enterpriseId || '';
        }
        if (userInfoObj.enterprise !== undefined) {
          const enterpriseObj = userInfoObj.enterprise || {};
          state.mainUserInfo.enterpriseId = enterpriseObj.id || '';
          state.additionalUserInfo.enterprise = enterpriseObj;
        } else if (state.mainUserInfo.role != 'employee') {
          state.additionalUserInfo.enterprise = {};
        }
        if (userInfoObj.usedPolitiAttestedOption !== undefined) {
          state.additionalUserInfo.usedPolitiAttestedOption = userInfoObj.usedPolitiAttestedOption;
        }
        if (state.mainUserInfo.role === 'buyer') {
          state.additionalUserInfo.enterprise = {};
        }
        if (userInfoObj.defaultLangTo) {
          state.additionalUserInfo.defaultLangTo = userInfoObj.defaultLangTo;
        }
        if (userInfoObj.favouriteInterLang) {
          state.additionalUserInfo.favouriteInterLang = userInfoObj.favouriteInterLang;
        }
        if (userInfoObj.onlineInterLang) {
          state.additionalUserInfo.onlineInterLang = userInfoObj.onlineInterLang;
        }
        if (userInfoObj.defaultPayoutMethod) {
          state.additionalUserInfo.defaultPayoutMethod = userInfoObj.defaultPayoutMethod;
        }
        if (userInfoObj.supplierNumber) {
          state.additionalUserInfo.supplierNumber = userInfoObj.supplierNumber;
        }
        if (userInfoObj.showStatisticsPage !== undefined) {
          state.additionalUserInfo.showStatisticsPage = userInfoObj.showStatisticsPage;
        }
        state.additionalUserInfo.allowForHotline = userInfoObj.allowForHotline;
        state.additionalUserInfo.isShared = userInfoObj.isShared;
        if (userInfoObj.accountStatus) {
          state.additionalUserInfo.accountStatus = userInfoObj.accountStatus;
        }
        if (userInfoObj.accountStatusExpiresAt) {
          state.additionalUserInfo.accountStatusExpiresAt = userInfoObj.accountStatusExpiresAt;
        }
        if (userInfoObj.sessionTypeStatus) {
          state.additionalUserInfo.sessionTypeStatus = userInfoObj.sessionTypeStatus;
        }
        if (userInfoObj.blockedForEnterprises) {
          state.additionalUserInfo.blockedForEnterprises = userInfoObj.blockedForEnterprises;
        }
        if (userInfoObj.canStandBy) {
          state.additionalUserInfo.canStandBy = userInfoObj.canStandBy;
        }
        refreshUserInfoCookies(state.mainUserInfo);
      },
      setUserInfoCookies(state) {
        refreshUserInfoCookies(state.mainUserInfo);
      },
      setAdminInfo(state, data = {}) {
        state.adminInfo.uid = data.admin_uid || '';
        state.adminInfo.firstName = data.admin_first_name || '';
        state.adminInfo.lastName = data.admin_last_name || '';
        state.adminInfo.email = data.admin_email || '';
      },
      setEmployeeInfo(state, employeeInfo = {}) {
        state.additionalUserInfo.enterprise = employeeInfo.enterprise;
      },
      setAdvertisementShownAt(state, data = {}) {
        state.additionalUserInfo.advertisementShownAt = data.advertisementShownAt;
      },
      setDrivingLicense(state) {
        state.additionalUserInfo.showDrivingPopup = false;
      },
      setPrivacyLevel(state) {
        state.additionalUserInfo.privacyPopup = false;
      },
      setWelcome(state) {
        state.additionalUserInfo.showWelcomePopup = false;
      },
      setLocale(state, value) {
        state.mainUserInfo.locale = value;
        refreshUserInfoCookies(state.mainUserInfo);
      },
      setEnterpriseLogo(state, logo) {
        if (state.additionalUserInfo.enterprise && state.additionalUserInfo.enterprise.id) {
          state.additionalUserInfo.enterprise.logo = logo;
        }
      },
      replaceEnterpriseRoles(state, arr) {
        state.additionalUserInfo.enterprise.member.roles = arr;
      },
      setFavouriteInterLang(state, data) {
        if (data) {
          state.additionalUserInfo.favouriteInterLang = data;
        }
      },
      setUsedPolitiAttestedOption(state, value) {
        state.additionalUserInfo.usedPolitiAttestedOption = value;
      }
    }
  };
};
