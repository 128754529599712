import createPreconnectElement from './helpers/createPreconnectElement';
import excludeArrayFromBaseArray from './helpers/excludeArrayFromBaseArray';
import searchArrayItemFromBaseArray from './helpers/searchArrayItemFromBaseArray';
import skGoogleAnalytics from './helpers/skGoogleAnalytics';
import GAHelper from './helpers/GAHelper';
import GADataConstructor from './helpers/GADataConstructor';
import detectDevice from './helpers/detectDevice';
import getOnlyDigits from './helpers/getOnlyDigits';
import isInvalidNotNegativeNumber from './helpers/isInvalidNotNegativeNumber';
import dateTime from './helpers/dateTime';
import getSelectedPreview from './helpers/getSelectedPreview';
import getPriseWithoutTaxes from './helpers/getPriseWithoutTaxes';
import getJobInfo from './helpers/getJobInfo';
import getProjectInfo from './helpers/getProjectInfo';
import getFullAddress from './helpers/getFullAddress';
import openBookingHelper from './helpers/openBookingHelper';
import openBookingFieldConfig from './helpers/openBookingFieldConfig';
import priceTemplates from './helpers/priceTemplates';
import getGodmodeLoginLink from './helpers/getGodmodeLoginLink';
import editAssignmentRestrictions from './helpers/editAssignmentRestrictions';
import clickOnDynamicContent from './helpers/clickOnDynamicContent';
import interpreterInfo from './helpers/interpreterInfo';
import feedbackInfo from './helpers/feedbackInfo';
import ticketSystemInfo from './helpers/ticketSystemInfo';
import formatParams from './helpers/formatParams';
import numbers from './helpers/numbers';
import getElementPosition from './helpers/getElementPosition';
import webRequests from './helpers/webRequests';
import getAdminInfo from './helpers/getAdminInfo';
import validation from './helpers/validation';
import file from './helpers/file';

export default {
  createPreconnectElement,
  excludeArrayFromBaseArray,
  searchArrayItemFromBaseArray,
  skGoogleAnalytics,
  GAHelper,
  GADataConstructor,
  detectDevice,
  getOnlyDigits,
  isInvalidNotNegativeNumber,
  dateTime,
  priceTemplates,
  getSelectedPreview,
  getPriseWithoutTaxes,
  getJobInfo,
  getProjectInfo,
  getFullAddress,
  openBookingHelper,
  openBookingFieldConfig,
  getGodmodeLoginLink,
  editAssignmentRestrictions,
  interpreterInfo,
  feedbackInfo,
  clickOnDynamicContent,
  ticketSystemInfo,
  formatParams,
  numbers,
  getElementPosition,
  webRequests,
  getAdminInfo,
  validation,
  file
};
