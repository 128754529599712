export default ({$fetcher: fetchData}) => {
  return {
    namespaced: true,
    state: {
      // used throughout the app for select and to access all employees in an enterprise
      employees: '',
      // paginated list of employees for the enterprise employees page
      enterpriseEmployees: '',
      currentEmployee: '',
      departments: '',
      allDepartments: '',
      enterprises: '',
      categories: '',
      ownerUid: '',
      managerJobEnterpriseId: '',
      filteredDepartments: '',
      addresses: '',
      emailQuery: '',
      departmentsPage: 1,
      departmentsPages: 1,
      employeesPages: 1,
      employeesPage: 1,
      employeesCount: 0,
      count: '',
    },
    getters: {
      getCategoriesList(state) {
        return state.categories || [];
      },
      getOwnerUid(state) {
        return state.ownerUid || '';
      },
      acceptedEmployees(state) {
        return state.employees && state.employees.length
          ? state.employees.filter((booker) => booker.status === 'accepted') : [];
      },
      getManagerJobEnterpriseId(state) {
        return state.managerJobEnterpriseId || '';
      }
    },
    actions: {
      getEnterprises({commit}) {
        return new Promise((resolve, reject) => {
          fetchData.getEnterprises().then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setEnterprises', data);
            resolve(data.enterprises);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getEmployees({commit, rootGetters}) {
        const uid = rootGetters['EnterpriseStore/getOwnerUid'];

        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
          fetchData.getEmployees(enterpriseId, uid).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setEmployees', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getCurrentEmployee({commit, rootGetters}) {
        const uid = rootGetters['UserInfoStore/userUid'];

        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
          fetchData.getCurrentEmployee(enterpriseId, uid).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setCurrentEmployee', data.employee);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      searchEmployees({commit, rootGetters}, params) {
        return new Promise((resolve, reject) => {
          fetchData.searchEmployees(rootGetters['UserInfoStore/enterpriseId'], params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setEnterpriseEmployees', data);
            commit('setEmployeesPaging', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      searchEnterprises({commit}, searchTerm) {
        return new Promise((resolve, reject) => {
          fetchData.searchEnterprises(searchTerm).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      addExistingEmployee(context, {entId, userId}) {
        return new Promise((resolve, reject) => {
          fetchData.addExistingEmployee(entId, userId).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      updateEmployee({commit, rootGetters}, {id, form, enterpriseId = null, skipSetEmployee = false}) {
        return new Promise((resolve, reject) => {
          fetchData.updateEmployee(enterpriseId || rootGetters['UserInfoStore/enterpriseId'], id, form).then((data) => {
            if (!skipSetEmployee) {
              commit('setEmployee', {data, id});
            }
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      removeEmployee({commit, rootGetters}, id) {
        return new Promise((resolve, reject) => {
          fetchData.removeEmployee(rootGetters['UserInfoStore/enterpriseId'], id).then((data) => {
            commit('removeEmployee', id);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      inviteEmployee({dispatch, rootGetters}, form) {
        return new Promise((resolve, reject) => {
          fetchData.inviteEmployee(rootGetters['UserInfoStore/enterpriseId'], form).then((data) => {
            dispatch('getEmployees').then(() => {
              resolve(data);
            });
          }).catch((response) => {
            reject(response);
          });
        });
      },
      resendInvite({rootGetters}, uid) {
        return new Promise((resolve, reject) => {
          fetchData.resendInvite(rootGetters['UserInfoStore/enterpriseId'], uid).then(() => {
            resolve();
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getDepartments({commit, rootGetters, rootState}, params) {
        return new Promise((resolve, reject) => {
          if (rootState?.filterItemsAmount && params) params.items = rootState.filterItemsAmount;
          const enterpriseId = params?.enterpriseId || rootGetters['UserInfoStore/enterpriseId'];
          fetchData.getDepartments(enterpriseId, params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setDepartments', data);
            commit('setDepartmentsPaging', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getAllDepartments({commit, rootGetters}, params) {
        return new Promise((resolve, reject) => {
          const enterpriseId = params?.enterpriseId || rootGetters['UserInfoStore/enterpriseId'];
          fetchData.getDepartments(enterpriseId, params).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setAllDepartments', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getDepartment({commit, rootGetters}, departmentId) {
        return new Promise((resolve, reject) => {
          fetchData.getDepartment(rootGetters['UserInfoStore/enterpriseId'], departmentId).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setFilteredDepartments', [data]);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      createDepartment({commit, rootGetters}, {form}) {
        return new Promise((resolve, reject) => {
          fetchData.createDepartment(rootGetters['UserInfoStore/enterpriseId'], form).then((data) => {
            commit('addDepartment', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      updateDepartment({commit, rootGetters}, {form, departmentId}) {
        return new Promise((resolve, reject) => {
          fetchData.updateDepartment(rootGetters['UserInfoStore/enterpriseId'], departmentId, form).then((data) => {
            commit('setDepartment', {data, departmentId});
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      removeDepartment({commit, rootGetters}, departmentId) {
        return new Promise((resolve, reject) => {
          fetchData.removeDepartment(rootGetters['UserInfoStore/enterpriseId'], departmentId).then((data) => {
            commit('removeDepartment', departmentId);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      getCategories({commit, rootGetters}, data) {
        return new Promise((resolve, reject) => {
          const promise = data && data.isOpenBooking && data.enterpriseId
            ? fetchData.getOpenCategories(data.enterpriseId, data.departmentId)
            : rootGetters['UserInfoStore/enterpriseId']
              ? fetchData.getCategories(rootGetters['UserInfoStore/enterpriseId'], data)
              : '';
          if (promise) {
            promise.then((data) => {
              if (data.code == 401) {
                commit('TTAuthStore/logOut', '', {root: true});
                commit('GlobalStore/remove404Page', '', {root: true});
                resolve('LOGOUT');
                return;
              }
              commit('setCategories', data);
              resolve();
            }).catch((response) => {
              reject(response);
            });
          }
        });
      },
      checkEmployeeEmail(context, email) {
        return new Promise((resolve, reject) => {
          fetchData.checkEmployeeEmail(email).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      cancelEmployeeEmailCheck() {
        return new Promise((resolve, reject) => {
          fetchData.cancelEmployeeEmailCheck().then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });
      },
      getAddresses({rootGetters, commit}, departmentId) {
        return new Promise((resolve, reject) => {
          fetchData.getAddresses(rootGetters['UserInfoStore/enterpriseId'], departmentId).then((data) => {
            if (data.code == 401) {
              commit('TTAuthStore/logOut', '', {root: true});
              commit('GlobalStore/remove404Page', '', {root: true});
              resolve('LOGOUT');
              return;
            }
            commit('setAddresses', data.addresses);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      createAddressForm({rootState}) {
        return new Promise((resolve) => {
          const form = new FormData();
          const {line1, line2, postcode, city, county, country, longitude, latitude} = rootState.FullAddressStore;

          form.append('address[line_1]', line1);
          form.append('address[line_2]', line2);
          form.append('address[city]', city);
          form.append('address[county]', county);
          form.append('address[country]', country);
          form.append('address[postcode]', postcode);
          form.append('address[longitude]', longitude);
          form.append('address[latitude]', latitude);

          resolve(form);
        });
      },
      createAddress({commit, rootGetters}, {form}) {
        return new Promise((resolve, reject) => {
          fetchData.createAddress(rootGetters['UserInfoStore/enterpriseId'], form).then((data) => {
            commit('addAddress', data);
            resolve(data?.id);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      updateAddress({commit, rootGetters}, {form, addressId}) {
        return new Promise((resolve, reject) => {
          fetchData.updateAddress(rootGetters['UserInfoStore/enterpriseId'], addressId, form).then((data) => {
            commit('setAddress', {data, addressId});
            resolve(addressId);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      removeAddress({commit, rootGetters}, addressId) {
        return new Promise((resolve, reject) => {
          fetchData.removeAddress(rootGetters['UserInfoStore/enterpriseId'], addressId).then((data) => {
            commit('removeAddress', addressId);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      }
    },

    mutations: {
      setOwnerUid(state, data) { state.ownerUid = data; },
      setManagerJobEnterpriseId(state, data) { state.managerJobEnterpriseId = data; },
      setEmployees(state, data) { state.employees = data.employees; },
      setEnterpriseEmployees(state, data) { state.enterpriseEmployees = data.collection; },
      setDepartments(state, data) {
        state.departments = data.departments;
      },
      setAllDepartments(state, data) {
        state.allDepartments = data.departments;
      },
      setEmployeesPaging(state, data) {
        state.employeesPages = data.pagination.pages;
        state.employeesPage = data.pagination.page;
        state.employeesCount = data.pagination.count;
      },
      setDepartmentsPaging(state, data) {
        state.departmentsPages = data.pages;
        if (data.page) state.departmentsPage = data.page;
        if (data.count) state.count = data.count;
      },
      setCategories(state, data) { state.categories = data.categories; },
      setEnterprises(state, data) { state.enterprises = data.enterprises; },
      setFilteredDepartments(state, data) { state.filteredDepartments = data; },
      addDepartment(state, data) {
        if (Array.isArray(state.departments)) {
          state.departments.push(data);
        }
      },
      setEmployee(state, {data, id}) {
        for (let i = 0, lim = state.enterpriseEmployees.length; i < lim; i += 1) {
          if (state.enterpriseEmployees[i].uid == id) {
            state.enterpriseEmployees.splice(i, 1, data.employee);
            break;
          }
        }
      },
      setEmailQuery(state, value) {
        state.emailQuery = value;
      },
      setDepartment(state, {data, departmentId}) {
        if (Array.isArray(state.departments)) {
          state.departments = state.departments.map((dep) => { return dep.id == departmentId ? data : dep; });
        }
      },
      setAddresses(state, data) { state.addresses = data; },
      setAddress(state, {data, addressId}) {
        if (Array.isArray(state.addresses)) {
          state.addresses = state.addresses.map((address) => { return address.id === addressId ? data : address; });
        }
      },
      setCurrentEmployee(state, data) {
        state.currentEmployee = data;
      },
      setCurrentEmployeeRoles(state, roles) {
        state.currentEmployee.roles = roles;
      },
      addAddress(state, data) { state.addresses.push(data); },
      removeEmployee(state, id) {
        for (let i = 0, lim = state.employees.length; i < lim; i += 1) {
          if (state.enterpriseEmployees[i].uid == id) {
            state.enterpriseEmployees.splice(i, 1);
            break;
          }
        }
      },
      removeDepartment(state, departmentId) {
        for (let i = 0, lim = state.departments.length; i < lim; i += 1) {
          if (state.departments[i].id == departmentId) {
            state.departments.splice(i, 1);
            break;
          }
        }
      },
      removeAddress(state, addressId) {
        if (Array.isArray(state.addresses)) {
          state.addresses = state.addresses.filter((address) => address.id !== addressId);
        }
      },
      removeEmployees(state) {
        state.enterpriseEmployees = '';
      },
      removeDepartments(state) {
        state.departments = '';
      },
      removeAddresses(state) {
        state.addresses = '';
      },
      removeCategories(state) {
        state.categories = '';
      },
      removeEnterprises(state) {
        state.enterprises = '';
      },
      removeEmailQuery(state) {
        state.emailQuery = '';
      },
      clearPaging(state) {
        state.pages = 1;
        state.page = 1;
        state.count = '';
      },
      clearStore(state) {
        state.employees = '';
        state.enterpriseEmployees = '';
        state.currentEmployee = '';
        state.departments = '';
        state.allDepartments = '';
        state.enterprises = '';
        state.categories = '';
        state.filteredDepartments = '';
        state.addresses = '';
        state.emailQuery = '';
        state.pages = 1;
        state.page = 1;
        state.count = '';
      }
    }
  };
};
