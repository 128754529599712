const simpleBuyerPriceStrategy = 'open_price';
const enterpriseMemberPriceStrategy = 'price_template';
const simpleBuyerName = 'buyer';
const defaultQualificationLevel = 5;
const translationRoutes = ['BuyerPostTranslationProject', 'OpenBookingTranslation', 'OpenBookingTranslationNb'];

export default (_this) => {
  return {
    getBookProductObjGA(jobObj) {
      return translationRoutes.includes(_this.$route.name)
        ? this.getTranslationProductObjGA(jobObj)
        : this.getInterpretationProductObjGA(jobObj);
    },
    getInterpretationProductObjGA(jobObj) {
      const langFromId = _this.$store.state.PostingStore.LanguagesStore.languageFrom;
      const langToId = _this.$store.state.PostingStore.LanguagesStore.languageTo;
      const languagePair = `${_this.$getLangName(langFromId)}-${_this.$getLangName(langToId)}`.toUpperCase();
      const demanderInternalTemplate = jobObj?.demanderPricingTemplate?.internal?.name || '';
      const demanderExternalTemplate = jobObj?.demanderPricingTemplate?.external?.name || '';
      const supplierHonorarTemplate = jobObj?.supplierPricingTemplate?.honorar?.name || '';
      const supplierCompanyTemplate = jobObj?.supplierPricingTemplate?.company?.name || '';
      const supplierEmployeeTemplate = jobObj?.supplierPricingTemplate?.employee?.name || '';
      const commonObj = {
        id: languagePair,
        category: 'interpretation',
        brand: _this.$store.getters['UserInfoStore/userIsEnterpriseMember'] ? enterpriseMemberPriceStrategy : simpleBuyerPriceStrategy,
        product: `level_${_this.$store.state.PostingStore.QualificationStore.qualification || defaultQualificationLevel}`,
        variant: _this.$store.state.PostingStore.AssignmentTypeStore.assignmentType,
        price: Number(_this.$store.getters['PostInterpretationStore/getJobDuration']).toFixed(2),
        quantity: 1
      };
      return demanderInternalTemplate && supplierHonorarTemplate
        ? {
          ...commonObj,
          ...{
            demanderInternalTemplate,
            demanderExternalTemplate,
            supplierHonorarTemplate,
            supplierCompanyTemplate,
            supplierEmployeeTemplate
          }
        }
        : commonObj;
    },
    getTranslationProductObjGA() {
      const langFromId = _this.$store.state.PostingStore.LanguagesStore.languageFrom;
      const langToId = _this.$store.state.PostingStore.LanguagesStore.languageTo;
      const languagePair = `${_this.$getLangName(langFromId)}-${_this.$getLangName(langToId)}`.toUpperCase();
      return {
        id: languagePair,
        category: 'translation',
        brand: _this.$store.getters['UserInfoStore/userIsEnterpriseMember'] ? enterpriseMemberPriceStrategy : simpleBuyerPriceStrategy,
        product: _this.$route.name === 'BuyerPostTranslationProject' ? 'loggedin' : 'loggedout',
        variant: _this.$store.getters['PostingStore/AttachmentsStore/fileType'],
        price: _this.$store.getters['PostingStore/AttachmentsStore/attachmentsCount'],
        quantity: 1
      };
    },
    getBookPurchaseObjGA(jobId, revenue) {
      return {
        id: `T_${jobId || '#'}`,
        revenue: revenue,
        affiliation: _this.$store.getters['UserInfoStore/enterpriseName'] || simpleBuyerName
      };
    },
    getBookEventCategory() {
      switch (_this.$route.name) {
        case 'PublicDirbook':
          return 'direct_interpretation-loggedout';
        case 'BuyerBookDirectly':
          return 'direct_interpretation';
        case 'BuyerPostInterpretation':
        case 'BuyerEditInterpretation':
          return 'regular_interpretation';
        case 'OpenBookingInterpretation':
        case 'OpenBookingInterpretationNb':
          return 'regular_interpretation-loggedout';
        case 'BuyerPostTranslationProject':
          return 'regular_translation';
        case 'OpenBookingTranslation':
        case 'OpenBookingTranslationNb':
          return 'regular_translation-loggedout';
      }
    },
    getBookEventValue() {
      return translationRoutes.includes(_this.$route.name)
        ? _this.$store.getters['PostingStore/AttachmentsStore/attachmentsCount']
        : _this.$store.getters['PostInterpretationStore/getJobDuration'];
    },
    getBookDraftObjGA(optionName) {
      const eventCategory = this.getBookEventCategory();
      const eventValue = this.getBookEventValue();

      return {
        eventCategory: eventCategory,
        eventAction: 'draft',
        eventLabel: optionName,
        value: eventValue
      };
    },
    getBookSubmitObjGA(jobObj = {}, eventCategory = this.getBookEventCategory()) {
      const productObj = this.getBookProductObjGA(jobObj);
      const purchaseObj = this.getBookPurchaseObjGA(jobObj.id, productObj.price);
      return {
        productObj,
        purchaseObj,
        eventCategory: eventCategory,
        eventLabel: productObj.variant,
        value: purchaseObj.revenue
      };
    },
    getBookCancelObjGA(jobId, eventLabel) {
      const refundObj = {id: `T_${jobId}`};
      return {
        refundObj,
        eventLabel: eventLabel || 'other'
      };
    },
    getBookEditObjGA(jobObj = {}) {
      const refundObj = {id: `T_${jobObj.id}`};
      const productObj = this.getBookProductObjGA(jobObj);
      const purchaseObj = this.getBookPurchaseObjGA(jobObj.id, productObj.price);
      return {
        refundObj,
        purchaseObj,
        productObj,
        eventLabel: _this.$isGodmode() ? 'demander_admin' : 'demander',
        value: 0
      };
    },
    getServiceFeedbackObjGA(ratingId) {
      const eventLabel = (_this.$store.getters['UserInfoStore/userIsInterpreter'])
        ? _this.$isGodmode() ? 'supplier_admin' : 'supplier'
        : _this.$isGodmode() ? 'demander_admin' : 'demander';
      return {
        eventCategory: 'job',
        eventAction: 'feedback',
        eventLabel,
        value: ratingId
      };
    },
    getFeedbackObjGA(value, eventAction) {
      return {
        eventCategory: 'feedback',
        eventAction,
        eventLabel: _this.$store.state.OneAssignmentStore.currentDiscussion.discussion ? _this.$store.state.OneAssignmentStore.currentDiscussion.discussion.supplierInfo.uid : '',
        value
      };
    },
    getOvertimeObjGA(overtimeDuration) {
      const jobObj = _this.$store.getters['OneAssignmentStore/storeJob'];
      const interpretationRequirement = jobObj.interpretationRequirement || {};
      const priceRequirement = jobObj.priceRequirement || {};
      const langFromId = interpretationRequirement.languageFromId;
      const langToId = interpretationRequirement.languageToId;
      const languagePair = `${_this.$getLangName(langFromId)}-${_this.$getLangName(langToId)}`.toUpperCase();
      const jobDuration = _this.$moment(interpretationRequirement.finishTime).diff(_this.$moment(interpretationRequirement.startTime), 'hours', true);
      const overtimeInHour = jobDuration - overtimeDuration;
      const productObj = {
        id: languagePair,
        category: 'interpretation',
        brand: priceRequirement.priceStrategy || simpleBuyerPriceStrategy,
        product: `level_${interpretationRequirement.qualificationId || defaultQualificationLevel}`,
        variant: interpretationRequirement.sessionType,
        price: Number(overtimeInHour).toFixed(2),
        quantity: 1
      };
      const purchaseObj = {
        id: `T_${jobObj.id}`,
        revenue: overtimeInHour,
        affiliation: jobObj.info.owner || simpleBuyerName
      };
      return {
        productObj,
        purchaseObj
      };
    },
    getEnterpriseObjGA(eventAction, eventLabel) {
      return {
        eventCategory: 'enterprise',
        eventAction,
        eventLabel,
        value: 0
      };
    }
  };
};
