(<template>
  <div class="post-form__section subject">
    <sk-select v-if="showCategoriesSelect || isTranslationProject"
               ref="category"
               :description="categoryText"
               :default-value="selectCategoryText"
               :items-list="categoriesList"
               :ignore-categories="true"
               :preselected-value="categoryId"
               :validation-name="categoryErrorName"
               :class="{'post-form__field--half-width': !onSeparateLines}"
               class="post-form__field subject__select"
               @focus="openCategoryHelper"
               @csvaluechanged="setCategory" />
    <sk-input v-if="!isTranslationProject || isOtherSubject"
              ref="subject"
              :description="subjectText"
              :placeholder="subjectPlaceholder"
              :preselected-value="subject"
              :validation-name="subjectErrorName"
              :class="{'post-form__field--half-width': !onSeparateLines}"
              :reg-exp="/<\/?[^>]+(>|$)/g"
              class="post-form__field subject__select"
              @fieldvaluechanged="setSubject"
              @blur="$GAHelper.trackDraftGA($GADataConstructor.getBookDraftObjGA('subject'))"
              @focus="openSubjectHelper" />
  </div>
</template>)

<script>
  import {mapGetters, mapState, mapMutations, mapActions} from 'vuex';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('PostingNotifierStore', [
        'showCategoriesSelect'
      ]),
      ...mapGetters('PostingStore/SubjectStore', [
        'getCategoryData'
      ]),
      ...mapState('PostingStore/SubjectStore', {
        categories: (state) => state.categoriesList || [],
        translationCategories: (state) => state.translationCategoriesList || [],
        subject: (state) => state.subject,
        categoryId: (state) => state.category.toString(),
        // validation names
        subjectErrorName: (state) => state.errors.subject.name,
        categoryErrorName: (state) => state.errors.category.name
      }),
      selectedCategoryData() {
        return this.categories.find((category) => category.id == this.categoryId);
      },
      categoryText() {
        return this.isTranslationProject ? this.$gettext('Subject') : this.$gettext('Category');
      },
      isOtherSubject() { return this.categoryId === 'other'; },
      subjectText() {
        if (this.isTranslationProject) {
          return this.$gettext('Enter subject');
        }

        return this.getCategoryData.subjectRequired ? this.$gettext('Subject for interpreter') : this.$gettext('Subject for interpreter (Optional)');
      },
      subjectPlaceholder() { return this.$gettext('Write here...'); },
      selectCategoryText() {
        return this.selectedCategoryData || !this.categoryId
          ? this.$gettext('Select a category')
          : this.$gettext('The selected category has been deleted');
      },
      computeCategorySelectionText() { return this.isTranslationProject ? this.$gettext('Select subject') : this.selectCategoryText; },
      currentLang() { return this.$route.params.lang; },
      paramsList() { return this.params || {}; },
      onSeparateLines() { return this.paramsList.onSeparateLines || false; },
      showAssignmentHelper() { return this.params.showAssignmentHelper; },
      isTranslationProject() { return this.params.isTranslationProject; },
      categoriesList() { return this.isTranslationProject ? this.translationCategories : this.categories; },
    },
    watch: {
      currentLang() {
        this.refetchCategories();
      }
    },
    methods: {
      ...mapActions('PostingNotifierStore', ['refetchCategories']),
      ...mapMutations('PostingStore/SubjectStore', [
        'setSubject',
        'setCategory'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setContext',
        'setPopoverReference',
      ]),
      openSubjectHelper() {
        if (this.showAssignmentHelper) {
          this.setContext('interpretation');
          this.setPopoverReference(this.$refs.subject);
          this.openHelper('subject');
        }
      },
      openCategoryHelper() {
        if (this.showAssignmentHelper) {
          this.setContext('interpretation');
          this.setPopoverReference(this.$refs.category);
          this.openHelper('category');
        }
      },
    }
  };
</script>

<style scoped>
  .subject__select {
    flex-grow: 1;
  }

  .subject__description {
    margin: 0 15px 20px;
  }
</style>
