(<template>
  <div class="step-2">
    <div v-for="textItem in upperTextItems"
         :key="textItem.item"
         :class="textItem.classes"
         v-html="textItem.item"></div>
    <div class="step-2__section-block">
      <div v-for="contentItem in contentItems"
           :key="contentItem.item"
           :class="contentItem.classes"
           v-html="contentItem.item"></div>
      <div class="step-2__input-wrapper">
        <sk-input :placeholder="bankAccountText"
                  :preselected-value="''"
                  :description="bankAccountText"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  :validation-name="bankAccountNumberErrorName"
                  :max-length="11"
                  class="bank-account-number__field"
                  @fieldvaluechanged="setBankAccountNumber" />
        <sk-input :placeholder="organizationNumberText"
                  :reg-exp="/[^0-9]/g"
                  :preselected-value="''"
                  :mob-numeric-keyboard="true"
                  :validation-name="organizationOrPersonalNumberErrorName"
                  :max-length="organizationNumberLength"
                  :description="organizationNumberText"
                  class="assignment-search__search-field"
                  @fieldvaluechanged="setOrganizationOrPersonalNumber" />
      </div>
      <sk-checkbox :item-text="acceptSalitaTerms"
                   :preselected-value="salitaTerms"
                   class="fai-filters__checkbox fai-filters__checkbox-spacing"
                   @checkboxvaluechanged="setSalitaTerms" />
      <sk-checkbox :item-text="acceptPayoutPartnerTerms"
                   :preselected-value="payoutPartnerTerms"
                   class="fai-filters__checkbox"
                   @checkboxvaluechanged="setPayoutPartnerTerms" />
      <div class="step-2__btn-wrapper">
        <button :disabled="showRegBtn"
                class="sk-btn sk-btn--default step-2__btn"
                @click="registerClick">{{ $gettext('Register') }}</button>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapMutations, mapState, mapGetters} from 'vuex';
  import constants from '@/modules/constants';

  export default {
    data() {
      return {
        currentDomain: this.$getString('domainData', 'platformName'),
        salitaTerms: false,
        payoutPartnerTerms: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      ...mapState('TTPayoutStore', {
        userHasCompany: ({payoutInfoWizard}) => payoutInfoWizard.userHasCompany,
        bankAccountNumber: ({newAccount}) => newAccount.bankAccountNumber,
        organizationNumber: ({newAccount}) => newAccount.organizationNumber,
        personalNumber: ({newAccount}) => newAccount.personalNumber,
        // errors
        bankAccountNumberErrorName: (state) => state.errors.bankAccountNumber.name,
        bankAccountNumberErrors: (state) => state.errors.bankAccountNumber.errors,
        personalNumberErrorName: (state) => state.errors.personalNumber.name,
        personalNumberErrors: (state) => state.errors.personalNumber.errors,
        organizationNumberErrorName: (state) => state.errors.organizationNumber.name,
        organizationNumberErrors: (state) => state.errors.organizationNumber.errors
      }),
      bankAccountText() {
        return this.$gettext('Bank account number');
      },
      organizationNumberText() {
        return this.userHasCompany ? this.$gettext('Organization number') : this.$gettext('Personal number');
      },
      organizationNumberLength() {
        return this.userHasCompany ? 9 : 11;
      },
      organizationOrPersonalNumberErrorName() {
        return this.userHasCompany ? this.organizationNumberErrorName : this.personalNumberErrorName;
      },
      organizationOrPersonalNumber() {
        return this.userHasCompany ? this.organizationNumber : this.personalNumber;
      },
      acceptSalitaTerms() {
        const domain = this.currentDomain;
        const template = this.$gettext('I accept %{domain}\'s <a class=\"sk-link\" href=\"https://salita.no/terms\" target=\"_blank\">terms of service</a>');
        return this.$gettextInterpolate(template, {domain});
      },
      acceptPayoutPartnerTerms() {
        const fileName = this.userHasCompany ? 'payoutpartner_agreement_for_organisations.pdf' : 'payoutpartner_agreement_for_individuals.pdf';
        const filePath = `${constants.API_PATH}/${fileName}`;
        const template = this.$gettext('I accept PayoutPartner AS <a class=\"sk-link\" href=\"%{filePath}" target=\"_blank\">freelance agreement</a>');
        return this.$gettextInterpolate(template, {filePath});
      },
      showRegBtn() {
        if (this.userHasCompany) {
          if (this.salitaTerms && this.payoutPartnerTerms) {
            if (this.bankAccountNumber && this.organizationNumber) {
              return false;
            }
            return true;
          }
          return true;
        } else if (!this.userHasCompany) {
          if (this.salitaTerms && this.payoutPartnerTerms) {
            if (this.bankAccountNumber && this.personalNumber) {
              return false;
            }
            return true;
          }
          return true;
        }

        return true;
      },
      upperTextItems() {
        if (this.userHasCompany) {
          return [{
            item: this.$gettextInterpolate(this.$gettext('%{domain} generates an invoice for you, both for interpreting and translation assignments. You just need to remember to report interpreting assignments within 48 hours, and Salita takes care of the rest.'), {domain: this.currentDomain}),
            classes: ['step-2__text']
          }];
        } else {
          return [{
            item: this.$gettextInterpolate(this.$gettext('%{domain} will pay your fee as salary after taxes and fees have been deducted.'), {domain: this.currentDomain}),
            classes: ['step-2__text']
          }];
        }
      },
      contentItems() {
        if (this.userHasCompany) {
          return [
            {
              item: '<div></div>',
              classes: ['step-2__logo']
            }, {
              item: this.$gettext('Register your information here so that Salita can generate invoices for you.'),
              classes: ['step-2__text', 'step-2__text-register--bold']
            }, {
              item: this.$gettext('Please provide bank account number and Norwegian organisation number.'),
              classes: ['step-2__text']
            }];
        } else {
          return [
            {
              item: '<div></div>',
              classes: ['step-2__logo']
            },
            {
              item: this.$gettext('Register your information here so that Salita can pay your salary.'),
              classes: ['step-2__text', 'step-2__text-register--bold']
            }, {
              item: this.$gettext('Please provide your bank account number and personal number.'),
              classes: ['step-2__text']
            }];
        }
      },
      primaryActionBtnText() {
        const template1 = this.$gettext('Start using PayoutPartner with %{domain} for Free');
        const template2 = this.$gettext('Sign up for %{domain}\'s Payroll services');
        return this.$gettextInterpolate(this.userHasCompany ? template1 : template2, {domain: this.currentDomain});
      },
      secondaryActionBtnText() {
        const template1 = this.$gettext('I have my own provider');
        const template2 = this.$gettext('I will find another way to send invoices');
        return this.userHasCompany ? template1 : template2;
      },
      // This is a tempoary hack until payoutPartner integation is disabled
      notCompany() {
        return !this.userHasCompany;
      }
    },
    methods: {
      ...mapMutations('TTPayoutStore', [
        'setUserHasOwnProvider',
        'setBankAccountNumber',
        'setPersonalNumber',
        'setOrganizationNumber',
        'setError',
        'removeErrors'
      ]),
      setSelection(userHasOwnProvider) {
        this.setUserHasOwnProvider(userHasOwnProvider);
        this.$emit('gotonext');
      },
      setSalitaTerms() {
        this.salitaTerms = !this.salitaTerms;
      },
      setPayoutPartnerTerms() {
        this.payoutPartnerTerms = !this.payoutPartnerTerms;
      },
      setOrganizationOrPersonalNumber(value) {
        if (this.userHasCompany) {
          this.setOrganizationNumber(value);
        } else {
          this.setPersonalNumber(value);
        }
      },
      validateForm() {
        let isValidForm = true;

        this.removeErrors();
        if (this.bankAccountNumber.length < 11) {
          isValidForm = false;
          this.setError({
            fieldName: 'bankAccountNumber',
            errorText: this.$gettext('Bank account must consist of 11 digits')
          });
          this.$store.commit('ErrorsStore/setError', {name: this.bankAccountNumberErrorName, errors: this.bankAccountNumberErrors});
        }
        if (this.organizationOrPersonalNumber.length < this.organizationNumberLength) {
          const errorText = this.userHasCompany ? this.$gettext('Organization number must consists of 9 digits') : this.$gettext('Personal number must consists of 11 digits');
          this.setError({
            fieldName: this.userHasCompany ? 'organizationNumber' : 'personalNumber',
            errorText: errorText
          });

          if (this.userHasCompany) {
            this.$store.commit('ErrorsStore/setError', {name: this.organizationNumberErrorName, errors: this.organizationNumberErrors});
          } else {
            this.$store.commit('ErrorsStore/setError', {name: this.personalNumberErrorName, errors: this.personalNumberErrors});
          }
          isValidForm = false;
        }

        return isValidForm;
      },
      async registerClick() {
        if (this.validateForm()) {
          await this.$store.dispatch('TTPayoutStore/newPayoutMethod');
          await this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid);
          this.$store.dispatch('ModalStore/closeModal');
        }
      }
    }
  };
</script>

<style>
  .step-2__text {
    margin-bottom: 20px;
  }

  .step-2__text a {
    text-decoration: underline;
  }

  .bank-account-number__field {
    margin-right: 20px;
  }

  .step-2__section-block {
    padding: 15px;
    border: 1px solid rgb(194, 188, 188);
    border-radius: 3px;
  }

  .step-2__input-wrapper {
    display: flex;
  }

  .step-2__text--bold {
    font-weight: bold;
  }

  .step-2__text-register--bold {
    font-weight: bold;
    font-size: 18px;
  }

  .step-2__text--margin {
    margin-left: 20px;
  }

  .step-2__logo {
    width: 150px;
    height: 38px;
    margin-top: 10px;
    margin-bottom: 20px;
    background-image: url(~@assets/imgs/salita/salita_logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }
</style>

<style scoped>
  .step-2__btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 20px -10px 0;
  }

  .step-2__btn {
    width: 200px;
    height: 50px;
    margin: 10px 10px 0;
    padding: 0 10px;
    line-height: 1.43;
    white-space: normal;
  }

  .fai-filters__checkbox-spacing {
    margin-bottom: 6px;
  }

  @media (max-width: 500px) {
    .step-2__btn-wrapper {
      flex-wrap: wrap;
    }

    .step-2__btn {
      width: 100%;
    }
  }
</style>
