export default ({$fetcher: fetchData, $moment: moment}) => {
  const filterDates = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD')
  };
  return {
    namespaced: true,
    state: {
      chartProgress: false,
      statistics: '',
      departmentsList: '',
      bookersList: '',
      languagesList: '',
      bookedInterpreters: [],
      bookedTranslators: [],
      filterValues: {
        targetLanguage: '',
        sourceLanguage: '',
        departments: [],
        bookers: [],
        interpreters: [],
        types: [],
        statuses: [],
        languages: [],
        qualifications: [],
        ...filterDates
      }
    },
    getters: {
      getFilteredData(state) {
        const {startDate, endDate, departments, bookers, interpreters, languages, sourceLanguage, targetLanguage, types, statuses} = state.filterValues;

        const dateFrom = startDate.replace(/-/g, '/');
        const dateTo = endDate.replace(/-/g, '/');

        return {
          dateFrom,
          dateTo,
          departments,
          bookers,
          interpreters,
          languages,
          sourceLanguage,
          targetLanguage,
          types,
          statuses
        };
      },
      getGroupBy() {
        return (currentChartId, scope) => {
          switch (currentChartId) {
            case 'line_type':
            case 'pie_type':
              return scope === 'interpretation' ? 'session_type' : 'subtask_type';
            case 'line_department':
            case 'bar_department':
              return 'department';
            case 'line_booker':
            case 'pie_booker':
              return 'booker';
            case 'line_interpreter':
            case 'pie_interpreter':
              return scope === 'interpretation' ? 'interpreter' : 'translator';
            case 'line_status':
            case 'pie_status':
              return scope === 'interpretation' ? 'job_status' : 'status';
            case 'line_language':
            case 'pie_language':
              return scope === 'interpretation' ? 'language' : 'language_pair';
          }
        };
      },
      getStatisticUrl: () => (enterpriseId, groupBy, scope, filters, format) => {
        const currentScope = scope === 'interpretation' ? 'job_statistics' : 'subtask_statistics';
        let path = `/v1/enterprises/${enterpriseId}/${currentScope}${format}?group_by=${groupBy}`;

        if (filters.dateFrom) {
          const field = scope === 'interpretation' ? 'scheduled' : 'created';
          path += `&${field}_at_from=${filters.dateFrom}`;
        }

        if (filters.dateTo) {
          const field = scope === 'interpretation' ? 'scheduled' : 'created';
          path += `&${field}_at_to=${filters.dateTo}`;
        }

        if (filters.types && filters.types.length > 0) {
          const field = scope === 'interpretation' ? 'session' : 'subtask';
          filters.types.forEach((type) => {
            path += `&${field}_types[]=${type}`;
          });
        }

        if (filters.statuses && filters.statuses.length > 0) {
          const field = scope === 'interpretation' ? 'job' : 'subtask';
          filters.statuses.forEach((status) => {
            path += `&${field}_statuses[]=${status}`;
          });
        }

        if (filters.departments && filters.departments.length > 0) {
          filters.departments.forEach((department) => {
            path += `&department_ids[]=${department}`;
          });
        }

        if (filters.bookers && filters.bookers.length > 0) {
          filters.bookers.forEach((booker) => {
            path += `&booker_uids[]=${booker}`;
          });
        }

        if (filters.interpreters && filters.interpreters.length > 0) {
          const field = scope === 'interpretation' ? 'interpreter_uids' : 'translator_ids';
          filters.interpreters.forEach((interpreter) => {
            path += `&${field}[]=${interpreter}`;
          });
        }

        if (filters.languages && filters.languages.length > 0) {
          filters.languages.forEach((language) => {
            path += `&language_ids[]=${language}`;
          });
        }

        if (filters.sourceLanguage) {
          path += `&source_language_ids[]=${filters.sourceLanguage}`;
        }
        if (filters.targetLanguage) {
          path += `&target_language_ids[]=${filters.targetLanguage}`;
        }
        if (scope === 'interpretation') {
          path += '&date_column=scheduled_at';
        }
        return path;
      }
    },
    actions: {
      fetchDepartmentsList({commit, rootGetters}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchSelectsData('departments', rootGetters['UserInfoStore/enterpriseId']).then((data) => {
            commit('setDepartmentsList', data);
            commit('setChartProgress', false);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      fetchBookersList({dispatch, commit}) {
        return new Promise((resolve, reject) => {
          dispatch('EnterpriseStore/getEmployees', null, {root: true}).then((data) => {
            commit('setBookersList', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      fetchBookedInterpreters({rootGetters, commit, getters}) {
        return new Promise((resolve, reject) => {
          const {dateFrom, dateTo} = getters.getFilteredData;
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
          fetchData.getBookedInterpreters(enterpriseId, dateFrom, dateTo).then((data) => {
            commit('setBookedInterpreters', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      fetchBookedTranslators({rootGetters, commit, getters}) {
        return new Promise((resolve, reject) => {
          const {dateFrom, dateTo} = getters.getFilteredData;
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
          fetchData.getBookedTranslators(enterpriseId, dateFrom, dateTo).then((data) => {
            commit('setBookedTranslators', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      fetchStatistics({commit, rootGetters, getters}, {chartId, scope}) {
        const groupedBy = getters.getGroupBy(chartId, scope);
        const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
        const url = getters.getStatisticUrl(enterpriseId, groupedBy, scope, getters.getFilteredData, '');

        return new Promise((resolve, reject) => {
          fetchData.getStatistics(url).then((data) => {
            commit('setStatistics', data);
            commit('setChartProgress', false);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      downloadStatistics({rootGetters, getters}, {chartId, scope}) {
        const groupedBy = getters.getGroupBy(chartId, scope);
        const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
        const url = getters.getStatisticUrl(enterpriseId, groupedBy, scope, getters.getFilteredData, '.csv');

        return new Promise((resolve, reject) => {
          fetchData.downloadStatistics(url).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
    },
    mutations: {
      setChartProgress(state, data) { state.chartProgress = data; },
      setBookedTranslators(state, data) { state.bookedTranslators = data; },
      setBookedInterpreters(state, data) { state.bookedInterpreters = data; },
      setStatistics(state, data) { state.statistics = data; },
      setDepartmentsList(state, data = {}) { state.departmentsList = data || []; },
      setBookersList(state, data = {}) { state.bookersList = data.employees || []; },
      setDepartments(state, value) { state.filterValues.departments = value; },
      setQualifications(state, value) { state.filterValues.qualifications = value; },
      setBookers(state, value) { state.filterValues.bookers = value; },
      setInterpreters(state, value) { state.filterValues.interpreters = value; },
      setTypes(state, value) { state.filterValues.types = value; },
      setStatuses(state, value) { state.filterValues.statuses = value; },
      setLanguages(state, value) { state.filterValues.languages = value; },
      setSourceLanguage(state, value) { state.filterValues.sourceLanguage = value; },
      setTargetLanguage(state, value) { state.filterValues.targetLanguage = value; },
      setDateRange(state, {startDate, endDate}) {
        state.filterValues.startDate = startDate;
        state.filterValues.endDate = endDate;
      },
      clearStore(state) {
        state.chartProgress = false;
        state.statistics = '';
        state.departmentsList = '';
        state.bookersList = '';
        state.languagesList = '';
        state.filterValues = {
          departments: [],
          bookers: [],
          interpreters: [],
          types: [],
          statuses: [],
          languages: [],
          sourceLanguage: '',
          targetLanguage: '',
          ...filterDates
        };
      }
    }
  };
};
