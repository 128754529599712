(<template>
  <div class="post-form__section">
    <sk-note :text="vatInfo"
             class="post-assignment__note" />
  </div>
</template>)

  <script>

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      vatInfo() {
        return this.$gettext('By choosing an MVA-fri category, you confirm that this assignment is outside VAT law. If required, we may request your accounting department for documentation regarding this.');
      }
    },
  };
  </script>

<style scoped>
    .post-form__section {
      margin-bottom: 32px;
    }

    .post-assignment__note {
      width: 100%;
    }
  </style>
