import helpers from '../helpers';

export default ({$fetcher: fetchData, $moment: moment}) => {
  return {
    namespaced: true,
    state: {
      jobStatement: '',
      expenses: '',
      form: {
        // job values
        manualFees: {
          booking: false,
          editing: false,
          travelBooking: false,
        },
        actualCreatedAt: '',
        bookingMechanism: '',
        // statement values
        actualSessionType: '',
        payable: true,
        chargeable: true,
        demanderShowedUp: true,
        supplierShowedUp: true,
        demanderStartTime: '',
        supplierStartTime: '',
        demanderFinishTime: '',
        supplierFinishTime: '',
        demanderTravelDiet: false,
        supplierTravelDiet: false,
        demanderExpenses: '',
        supplierExpenses: '',
        demanderDistance: '',
        supplierDistance: '',
        demanderTravelTime: '',
        supplierTravelTime: '',
        demanderDelayCausedBy: '',
        supplierDelayCausedBy: '',
        supplierTravelType: '',
        demanderTravelType: '',
        demanderInvoiceDiscountPercentage: '',
        supplierInvoiceDiscountPercentage: '',
        errors: {
          demanderInvoiceDiscountPercentage: {name: 'demanderInvoiceDiscountPercentage', errors: []},
          supplierInvoiceDiscountPercentage: {name: 'supplierInvoiceDiscountPercentage', errors: []}
        }
      }
    },
    getters: {
      statement(state) {
        return state.jobStatement?.statement || {};
      },
      expenses(state) {
        return state.expenses || [];
      },
      jobSupplierInfo(state) {
        return state.jobStatement?.supplierInfo || {};
      },
      jobDemanderInfo(state) {
        return state.jobStatement?.demanderInfo || {};
      }
    },
    actions: {
      fetchJobStatement({commit}, {jobId, statementId}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchJobStatement(jobId, statementId)
            .then((data) => {
              commit('setStatement', data);
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      updateJobStatement({commit, dispatch}, {jobId, statementId}) {
        return new Promise((resolve, reject) => {
         dispatch('createForm')
            .then((form) => {
              fetchData.updateJobStatement(jobId, statementId, form)
                .then((data) => {
                  dispatch('OneAssignmentStore/getJobById', jobId, {root: true})
                    .then((job = {}) => {
                      commit('setStatement', data);
                      commit('setJobFormValues', job.job);
                      resolve(data);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
                .catch((error) => {
                  reject(error);
                });
            });
        });
      },
      createForm({state, rootState}) {
        return new Promise((resolve) => {
          const form = new FormData();
          form.append('track_type', 'job_statement_updated');
          form.append('session_type', state.form.actualSessionType);
          form.append('manual_booking_fee_applied', state.form.manualFees.booking);
          form.append('manual_editing_fee_applied', state.form.manualFees.editing);
          form.append('manual_travel_booking_fee_applied', state.form.manualFees.travelBooking);
          form.append('actual_created_at', state.form.actualCreatedAt);
          form.append('booking_mechanism', state.form.bookingMechanism);
          if (rootState.PostingStore.SubjectStore.category) {
            form.append('job_category_item_id', rootState.PostingStore.SubjectStore.category);
          }

          form.append('chargeable', state.form.chargeable);
          form.append('demander_showed_up', state.form.demanderShowedUp);
          form.append('demander_actual_finish_time', state.form.demanderFinishTime);
          form.append('demander_actual_start_time', state.form.demanderStartTime);
          form.append('demander_delay_caused_by', state.form.demanderDelayCausedBy);
          form.append('demander_invoice_discount_percentage', state.form.demanderInvoiceDiscountPercentage || 0);
          form.append('demander_travel_distance', state.form.demanderDistance || 0);
          form.append('demander_travel_expenses_amount_override', state.form.demanderExpenses || 0);
          form.append('demander_travel_time', state.form.demanderTravelTime || 0);
          form.append('demander_travel_diet_enabled', state.form.demanderTravelDiet);

          form.append('payable', state.form.payable);
          form.append('supplier_showed_up', state.form.supplierShowedUp);
          form.append('supplier_actual_finish_time', state.form.supplierFinishTime);
          form.append('supplier_actual_start_time', state.form.supplierStartTime);
          form.append('supplier_delay_caused_by', state.form.supplierDelayCausedBy);
          form.append('supplier_invoice_discount_percentage', state.form.supplierInvoiceDiscountPercentage || 0);
          form.append('supplier_travel_distance', state.form.supplierDistance || 0);
          form.append('supplier_travel_expenses_amount_override', state.form.supplierExpenses || 0);
          form.append('supplier_travel_time', state.form.supplierTravelTime || 0);
          form.append('supplier_travel_diet_enabled', state.form.supplierTravelDiet);
          form.append('supplier_travel_diet_enabled', state.form.supplierTravelDiet);
          form.append('demander_travel_type', state.form.demanderTravelType);
          form.append('supplier_travel_type', state.form.supplierTravelType);

          resolve(form);
        });
      },
      setError({state, commit}, {fieldName, errorText}) {
        commit('setFormError', {fieldName, errorText});
        commit('ErrorsStore/setError', {name: state.form.errors[fieldName].name, errors: state.form.errors[fieldName].errors}, {root: true});
      },
    },
    mutations: {
      setStatement(state, data) {
        state.jobStatement = data;
        state.expenses = data.expenses;
      },
      setFormManualFee(state, {key, value}) {
        state.form.manualFees[key] = value;
      },
      setFormDateValue(state, {key, value}) {
        state.form[key] = helpers.dateTime.setDate(moment, {
          currentTime: state.form[key],
          value
        });
      },
      setFormTimeValue(state, {key, value}) {
        state.form[key] = helpers.dateTime.setTime(moment, {
          currentTime: state.form[key],
          value
        });
      },
      setFormValue(state, {key, value}) {
        state.form[key] = value;
      },
      setJobFormValues(state, job = {}) {
        state.form.manualFees.booking = !!job.info?.manualBookingFeeApplied;
        state.form.manualFees.editing = !!job.info?.manualEditingFeeApplied;
        state.form.manualFees.travelBooking = !!job.info?.manualTravelBookingFeeApplied;
        if (job.actualCreatedAt) {
          state.form.actualCreatedAt = job.actualCreatedAt;
        }
        state.form.bookingMechanism = job.bookingMechanism || 'platform';
      },
      setStatementFormValues(state, statement = {}) {
        state.form.actualSessionType = statement.sessionType || 'phone';
        state.form.payable = !!statement.payable;
        state.form.chargeable = !!statement.chargeable;
        state.form.demanderShowedUp = !!statement.demanderShowedUp;
        state.form.supplierShowedUp = !!statement.supplierShowedUp;
        state.form.demanderStartTime = statement.demanderActualStartTime || '';
        state.form.supplierStartTime = statement.supplierActualStartTime || '';
        state.form.demanderFinishTime = statement.demanderActualFinishTime || '';
        state.form.supplierFinishTime = statement.supplierActualFinishTime || '';
        state.form.demanderDelayCausedBy = statement.demanderDelayCausedBy || '';
        state.form.supplierDelayCausedBy = statement.supplierDelayCausedBy || '';
        state.form.demanderTravelDiet = !!statement.demanderTravelDietEnabled;
        state.form.supplierTravelDiet = !!statement.supplierTravelDietEnabled;
        state.form.demanderExpenses = statement.demanderTravelExpensesAmountOverride || '0';
        state.form.supplierExpenses = statement.supplierTravelExpensesAmountOverride || '0';
        state.form.demanderDistance = statement.demanderTravelDistance || '0';
        state.form.supplierDistance = statement.supplierTravelDistance || '0';
        state.form.demanderTravelTime = statement.demanderTravelTime || '0';
        state.form.supplierTravelTime = statement.supplierTravelTime || '0';
        state.form.supplierTravelType = statement.supplierTravelType || '';
        state.form.demanderTravelType = statement.demanderTravelType || '';
        state.form.demanderInvoiceDiscountPercentage = statement.demanderInvoiceDiscountPercentage || '0';
        state.form.supplierInvoiceDiscountPercentage = statement.supplierInvoiceDiscountPercentage || '0';
      },
      setFormError(state, {fieldName, errorText}) {
        state.form.errors[fieldName].errors = [...state.form.errors[fieldName].errors, errorText];
      },
      clearErrors(state) {
        state.form.errors.demanderInvoiceDiscountPercentage.errors = [];
        state.form.errors.supplierInvoiceDiscountPercentage.errors = [];
      },
      clearStore(state) {
        state.jobStatement = '';
        state.form = {
          // job values
          manualFees: {
            booking: false,
            editing: false,
            travelBooking: false,
          },
          actualCreatedAt: '',
          bookingMechanism: '',
          // statement values
          actualSessionType: '',
          payable: true,
          chargeable: true,
          demanderShowedUp: true,
          supplierShowedUp: true,
          demanderStartTime: '',
          supplierStartTime: '',
          demanderFinishTime: '',
          supplierFinishTime: '',
          demanderInvoiceDiscountPercentage: '',
          supplierInvoiceDiscountPercentage: '',
          demanderTravelDiet: false,
          supplierTravelDiet: false,
          demanderExpenses: '',
          supplierExpenses: '',
          demanderDistance: '',
          supplierDistance: '',
          demanderTravelTime: '',
          supplierTravelTime: '',
          demanderDelayCausedBy: '',
          supplierDelayCausedBy: '',
          supplierTravelType: '',
          demanderTravelType: '',
          errors: {
            demanderInvoiceDiscountPercentage: {name: 'demanderDiscountError', errors: []},
            supplierInvoiceDiscountPercentage: {name: 'supplierDiscountError', errors: []}
          }
        };
      }
    }
  };
};
