import { render, staticRenderFns } from "./BookingReferenceSection.vue?vue&type=template&id=ca5a8250&scoped=true&"
import script from "./BookingReferenceSection.vue?vue&type=script&lang=js&"
export * from "./BookingReferenceSection.vue?vue&type=script&lang=js&"
import style0 from "./BookingReferenceSection.vue?vue&type=style&index=0&id=ca5a8250&prod&lang=css&"
import style1 from "./BookingReferenceSection.vue?vue&type=style&index=1&id=ca5a8250&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca5a8250",
  null
  
)

export default component.exports