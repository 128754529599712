import assignmentsRoutes from './buyer_assignments_routes';
import projectsRoutes from './buyer_project_routes';
import enterpriseRoutes from './buyer_enterprise_routes';
import enterpriseGridRoutes from './buyer_enterprise_grid_routes';
import preferencesRoutes from './buyer_preferences_routes';
import testVideoRoutes from '../testVideoRoutes';
import statisticsRoutes from './buyer_statistics_routes';

export default {
  path: '/:lang/:country/b',
  meta: {
    requiresAuth: true,
    buyerOnly: true
  },
  component: () => import('@/pages/shared_pages/sh_Root'),
  children: [
    {
      path: '/',
      redirect: {name: 'BuyerHome'}
    },
    {
      path: 'home',
      name: 'BuyerHome',
      component: () => import('@/pages/BuyerPages/b_Home')
    },
    {
      path: 'change_password',
      name: 'BuyerChangePassword',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/b_ChangePassword')
    },
    {
      path: 'interpreters',
      name: 'BuyerInterpreters',
      component: () => import('@/pages/BuyerPages/b_Interpreters')
    },
    {
      path: 'calendar',
      name: 'BuyerCalendar',
      meta: {subPage: true},
      component: () => import('@/pages/shared_pages/sh_Calendar')
    },
    {
      path: 'capacity',
      name: 'BuyerCapacity',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/b_Capacity')
    },
    {
      path: 'profile/:id',
      name: 'BuyerUserProfile',
      meta: {subPage: true},
      component: () => import('@/pages/shared_pages/profile/sh_ProfileShow')
    },
    {
      path: 'profile/:id/edit',
      name: 'BuyerUserProfileEdit',
      meta: {
        currentUserOnly: true,
        subPage: true
      },
      component: () => import('@/pages/shared_pages/profile/sh_ProfileEdit')
    },
    {
      path: 'upgrade',
      name: 'BuyerUpgradeProfile',
      meta: {
        notEnterpriseOnly: true,
        subPage: true
      },
      component: () => import('@/pages/BuyerPages/enterprise/b_UpgradeProfile')
    },
    {
      path: 'hotline_codes',
      name: 'BuyerHotlineCodes',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/enterprise/b_HotlineCodes')
    },
    {
      path: 'payments',
      name: 'BuyerPayments',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/b_PaymentMethods')
    },
    {
      path: 'power-bi',
      name: 'BuyerPowerBI',
      meta: {subPage: true},
      component: () => import('@/pages/shared_pages/statistics_pages/b_PowerBI')
    },
    {
      path: 'interpretation-statistics',
      name: 'BuyerInterpretationStatistics',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/b_InterpretationStatistics')
    },
    {
      path: 'tickets',
      name: 'BuyerTicketSystem',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/ticket_system_pages/b_TicketSystem')
    },
    {
      path: 'tickets/:id',
      name: 'BuyerOneTicket',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/ticket_system_pages/b_OneTicket')
    },
    {
      path: 'tickets-statistics',
      name: 'BuyerTicketsStatistics',
      meta: {subPage: true},
      component: () => import('@/pages/BuyerPages/ticket_system_pages/b_TicketsStatistics')
    },
    {
      path: 'app_android',
      name: 'DownloadApkBuyer',
      component: () => import('@/pages/shared_pages/sh_DownloadApk')
    },
    assignmentsRoutes,
    enterpriseRoutes,
    enterpriseGridRoutes,
    preferencesRoutes,
    statisticsRoutes,
    projectsRoutes,
    testVideoRoutes('Buyer')
  ]
};
