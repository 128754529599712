(<template>
  <form class="post-form"
        @submit.prevent="">
    <post-section v-for="(section, index) in filteredSections"
                  :key="index"
                  :title="section.title"
                  :class="section.classes"
                  :lines-list="section.lines">

      <booking-group-fields :lines-list="section.lines" />

      <div v-if="isLastSection(index)"
           class="post-form__field-group">
        <div class="post-form__field">
          <div :class="{'in-progress': formProgress}"
               class="post-form__post-buttons is-progress-bar">
            <template v-if="showActionButtons">
              <slot name="closeButton"></slot>
              <slot name="submitButtonWithAddition"></slot>
              <button class="sk-btn sk-btn--default post-form__post-btn"
                      @click.prevent.stop="submit">{{ submitButtonText }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </post-section>
  </form>
</template>)

<script>
  import {mapState} from 'vuex';
  import PostSection from '@/components/booking_components/PostSection';
  import BookingGroupFields from '@/components/booking_components/BookingGroupFields';
  import PostingValidation from '@/prototypes/PostingValidation';

  export default {
    components: {
      'post-section': PostSection,
      'booking-group-fields': BookingGroupFields
    },
    extends: PostingValidation,
    props: {
      submitButtonText: {
        type: String,
        default: ''
      },
      formProgress: {
        type: Boolean
      },
      sectionsList: {
        type: Array,
        default: () => []
      },
      validateFunc: {
        type: Function,
        default: () => {}
      },
      errorHandlingFunc: {
        type: Function,
        default: () => {}
      },
      successHandlingFunc: {
        type: Function,
        default: () => {}
      },
      sendInfoFunc: {
        type: Function,
        default: () => {}
      },
      summaryObj: {
        type: Object,
        required: false,
        default() {
          return null;
        }
      },
      showActionButtons: {
        type: Boolean,
        default: true
      },
    },
    computed: {
      ...mapState('PostingStore', {
        validationResult: ({validationResult}) => validationResult,
      }),
      ...mapState('PostingNotifierStore', {
        isOnTranslation: ({isOnTranslation}) => isOnTranslation,
      }),
      filteredSections() {
        return this.sectionsList.filter((section) => {
          return this.showSection(section.lines);
        }) || [];
      }
    },
    methods: {
      isLastSection(index) {
        return this.filteredSections.length - 1 === index;
      },
      getProductObjGA() {
        return this.$GADataConstructor.getBookProductObjGA();
      },
      genComponentKey(type, index) {
        return `${type}-${index}`;
      },
      showSection(lines) {
        return lines.find((line) => {
          return line.components.find(this.canShowComponent);
        });
      },
      canShowComponent(component) {
        return component.condition !== undefined ? component.condition : true;
      },
      sendInfo() {
        if (this.validateFunc()) {
          const directAward = this.$store.getters['PostInterpretationStore/getDirectAward'];
          this.sendInfoFunc().then((data = {}) => {
            this.$emit('postassignsuccess');
            this.trackGA(data);
            this.$store.dispatch('ModalStore/closeModal');
            this.successHandlingFunc(data);
            if (directAward && ['in_person', 'videoroom'].includes(data.job?.interpretationRequirement?.sessionType)) {
              this.openJobAsSupplier(data.job);
            }
          }).catch((error) => {
            this.$emit('postassignfailed');
            this.errorHandlingFunc(error, this);
          });
        } else {
          this.$emit('postassignsuccess');
        }
      },
      submit() {
        if (this.validateFunc()) {
          if (this.summaryObj) {
            this.openSummaryModal();
          } else {
            this.sendInfo();
            this.setDirectAward(false);
          }
        } else {
          this.$scrollToErrors();
          if (this.validationResult.missingData) this.$GAHelper.trackReviewGA(`${this.$GADataConstructor.getBookEventCategory()}_missing-data`);
          if (this.validationResult.wrongData) this.$GAHelper.trackReviewGA(`${this.$GADataConstructor.getBookEventCategory()}_wrong-data`);
          this.setDirectAward(false);
        }
      },
      openSummaryModal() {
        const commonSummary = {
          title: this.$gettext('Review'),
          context: this
        };
        this.$store.commit('ModalStore/setModal', {
          component: 'post-summary',
          classes: ['sk-modal--new', 'booking-modal'],
          data: {...commonSummary, ...this.summaryObj}
        });
        this.$GAHelper.trackReviewGA(`${this.$GADataConstructor.getBookEventCategory()}_success`);
        this.$GAHelper.trackCartEventsGA('add', this.getProductObjGA());
      },
      trackGA(job) {
        const jobObj = job.job || job.translationProject || {};
        if (this.$store.state.PostInterpretationStore.job.job_id) {
          this.$GAHelper.trackEditGA(this.$GADataConstructor.getBookEditObjGA(jobObj));
        } else {
          this.$GAHelper.trackSubmitGA(this.$GADataConstructor.getBookSubmitObjGA(jobObj));
        }
      },
      openJobAsSupplier(job) {
        this.$store.dispatch('OneAssignmentStore/getSupplierLink', {
          jobId: job.id,
          interpreterUid: job.directInterUid
        }).then((data) => {
          window.open(data.link, '_blank');
        }).catch((error) => {
          this.$store.commit('ModalStore/setModalErrors', error);
        });
      },
      setDirectAward(value) {
        if (!this.isOnTranslation) this.$store.commit('PostInterpretationStore/setDirectAward', value);
      }
    }
  };
</script>

<style>
  .booking-modal .sk-modal__title {
    padding-top: 20px;
  }

  .sk-select--edit-btn:focus,
  .sk-select-btn:focus {
    box-shadow: var(--default-focus-shadow);
    outline: none;
  }
</style>
