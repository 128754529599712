export default ({$fetcher: fetchData, $moment: moment}) => {
  const filterDates = {
    dateFrom: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    dateTo: moment().endOf('month').format('YYYY-MM-DD'),
  };
  return {
    namespaced: true,
    state: {
      statistics: '',
      departmentsList: '',
      languagesList: '',
      filterValues: {
        departments: [],
        types: [],
        statuses: [],
        languages: [],
        qualifications: [],
        ...filterDates
      }
    },
    getters: {
      getGroupedByJobStatus(state) {
        return state.statistics.groupedByJobStatus || [];
      },
      getGroupedByDepartment(state) {
        return state.statistics.groupedByDepartment || [];
      },
      getGroupedByQualification(state) {
        return state.statistics.groupedByQualification || [];
      },
      getGroupedByScheduledAt(state) {
        return state.statistics.groupedByScheduledAt || [];
      },
      getGroupedBySessionType(state) {
        return state.statistics.groupedBySessionType || [];
      },
      getGroupedByLanguage(state) {
        return state.statistics.groupedByLanguage || [];
      },
      getTotalCount(state) {
        return state.statistics.totalCount || 0;
      },
      getStatisticsUrl: (state) => (enterpriseId, exportFormat = '', format = '') => {
        const filters = state.filterValues;
        const startDate = moment(filters.dateFrom);
        const endDate = moment(filters.dateTo);
        const groupByPeriod = endDate.diff(startDate, 'months') >= 12 ? 'month' : 'day';

        let path = `/v1/enterprises/${enterpriseId}/job_statistics/v2${format}?group_by_period=${groupByPeriod}`;

        if (exportFormat) {
          path += `&export_format=${exportFormat}`;
        }

        if (filters.dateFrom) {
          path += `&scheduled_at[date_from]=${filters.dateFrom}`;
        }

        if (filters.dateTo) {
          path += `&scheduled_at[date_to]=${filters.dateTo}`;
        }

        if (filters.types && filters.types.length > 0) {
          filters.types.forEach((type) => {
            path += `&session_types[]=${type}`;
          });
        }

        if (filters.statuses && filters.statuses.length > 0) {
          filters.statuses.forEach((status) => {
            path += `&job_statuses[]=${status}`;
          });
        }

        if (filters.departments && filters.departments.length > 0) {
          filters.departments.forEach((department) => {
            path += `&department_ids[]=${department}`;
          });
        }

        if (filters.qualifications && filters.qualifications.length > 0) {
          filters.qualifications.forEach((qualification) => {
            path += `&delivered_qualification_ids[]=${qualification}`;
          });
        }

        if (filters.languages && filters.languages.length > 0) {
          filters.languages.forEach((language) => {
            path += `&delivered_language_ids[]=${language}`;
          });
        }

        return path;
      }
    },
    actions: {
      fetchDepartmentsList({commit, rootGetters}) {
        return new Promise((resolve, reject) => {
          fetchData.fetchSelectsData('departments', rootGetters['UserInfoStore/enterpriseId']).then((data) => {
            commit('setDepartmentsList', data);
            resolve(data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      fetchInterpretationStatistics({rootGetters, commit, getters}) {
        return new Promise((resolve, reject) => {
          const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
          const url = getters.getStatisticsUrl(enterpriseId);

          fetchData.getStatistics(url).then((data) => {
            commit('setStatistics', data);
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
      downloadStatistics({rootGetters, getters}, format) {
        const enterpriseId = rootGetters['UserInfoStore/enterpriseId'] || rootGetters['EnterpriseStore/getManagerJobEnterpriseId'];
        const url = getters.getStatisticsUrl(enterpriseId, format, '.csv');

        return new Promise((resolve, reject) => {
          fetchData.downloadStatistics(url).then((data) => {
            resolve(data);
          }).catch((response) => {
            reject(response);
          });
        });
      },
    },
    mutations: {
      setStatistics(state, data) { state.statistics = data; },
      setDepartmentsList(state, data = {}) { state.departmentsList = data || []; },
      setDepartments(state, value) { state.filterValues.departments = value; },
      setQualifications(state, value) { state.filterValues.qualifications = value; },
      setTypes(state, value) { state.filterValues.types = value; },
      setStatuses(state, value) { state.filterValues.statuses = value; },
      setLanguages(state, value) { state.filterValues.languages = value; },
      setDateRange(state, {startDate, endDate}) {
        state.filterValues.dateFrom = startDate;
        state.filterValues.dateTo = endDate;
      },
      clearFilters(state) {
        state.filterValues = {
          departments: [],
          types: [],
          statuses: [],
          languages: [],
          qualifications: [],
          ...filterDates
        };
      },
      clearStore(state) {
        state.statistics = '';
        state.departmentsList = '';
        state.languagesList = '';
        state.filterValues = {
          departments: [],
          types: [],
          statuses: [],
          languages: [],
          qualifications: [],
          ...filterDates
        };
      }
    }
  };
};
