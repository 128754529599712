import GATrackedMutations from '@/modules/GATrackedMutations';

export default (_this) => {
  return (store) => {
    store.subscribe((mutation, state) => {
      if (mutation.type === 'executeNotTrackedMutation') {
        store.commit(mutation.payload.mutationName, mutation.payload.payload);
        store.commit('clearNotTrackedMutation');
        return;
      }
      GATrackedMutations.forEach((trackedObj) => {
        const mutationType = `${trackedObj.storeName}/${trackedObj.mutationName}`;
        if (mutationType === mutation.type && state.notTrackedMutation !== mutationType) {
          const draftObj = _this.$GADataConstructor.getBookDraftObjGA(trackedObj.recordValue);
          _this.$GAHelper.trackDraftGA(draftObj);
        }
      });
    });
  };
};
