(<template>
  <form class="add-employee__main-wrapper"
        @submit.prevent="checkForm">
    <p v-if="currentDepartment"
       class="add-employee__extra-text"
       v-html="selectedDepartmentText"></p>
    <sk-input :description="firstNameText"
              :preselected-value="firstName"
              :margin-bottom="true"
              :placeholder="firstNameText"
              :autofocus="true"
              :validation-name="errors.firstName.name"
              :reg-exp="/<\/?[^>]+(>|$)/g"
              class="add-employee__half-width"
              @fieldvaluechanged="catchFirstName" />
    <sk-input :description="lastNameText"
              :preselected-value="lastName"
              :margin-bottom="true"
              :placeholder="lastNameText"
              :validation-name="errors.lastName.name"
              :reg-exp="/<\/?[^>]+(>|$)/g"
              class="add-employee__half-width"
              @fieldvaluechanged="catchLastName" />
    <component :is="emailComponent"
               :description="emailText"
               :placeholder="emailText"
               :preselected-value="email"
               :type="'email'"
               :validation-name="errors.email.name"
               :submit-after-condition="isValidEmail"
               @startprogress="startProgress"
               @stopprogress="stopProgress"
               @fieldvaluechanged="catchEmail"
               @onsuccess="catchValidEmail"
               @onerror="catchInvalidEmail"
               @onreset="resetEmailErrors" />
    <div class="add-employee__tel-row">
      <sk-select :type="'countryCodes'"
                 :description="countryCodeText"
                 :default-value="countryCodeText"
                 :preselected-value="telCode"
                 :mob-numeric-keyboard="true"
                 class="add-employee__code-select"
                 @csvaluechanged="catchTelCode" />
      <sk-input :description="phoneText"
                :preselected-value="telNumber"
                :margin-bottom="true"
                :placeholder="phoneText"
                :type="'tel'"
                :reg-exp="/[^0-9]/g"
                :mob-numeric-keyboard="true"
                :validation-name="errors.phoneNumber.name"
                class="add-employee__number-input"
                @fieldvaluechanged="catchTelNumber" />
    </div>
    <div v-if="isAnyDepartments && !currentDepartment"
         class="add-employee__departments-row">
      <sk-multiselect :items-list="departmentsList"
                      :preselected-value="departments"
                      :description="departmentsText"
                      :default-value="departmentsText"
                      class="add-employee__departments"
                      @checkboxeschanged="changeDepartmentsHandler" />
      <button v-if="!hideAddDepartment"
              class="sk-select-btn sk-select-btn__add-department"
              @click.prevent.stop="openAddDepartmentModal"></button>
    </div>
    <div class="add-employee__info">
      <sk-note :text="bookingRefInfoText"
               class="add-employee__info--note" />
      <sk-input :description="buyerDescription"
                :placeholder="buyerDescription"
                :preselected-value="buyerRef"
                :disabled="disableBuyerReference"
                :reg-exp="/[^0-9a-zA-ZÆØÅæøå\/:\-\\ ]/g"
                :validation-name="errors.bookingReference.name"
                class="add-employee__booking-ref"
                @fieldvaluechanged="catchBuyerRef" />
      <p v-if="choosenDepartmentsLength"
         class="reference-hint"
         v-html="refHint('order')"></p>
      <sk-input :description="orderDescription"
                :placeholder="orderDescription"
                :preselected-value="orderRef"
                :disabled="disableOrderReference"
                :reg-exp="/[^0-9a-zA-ZÆØÅæøå\/:\-\\ ]/g"
                :validation-name="errors.paymentBookingReference.name"
                class="add-employee__booking-ref"
                @fieldvaluechanged="catchOrderRef" />
      <p v-if="choosenDepartmentsLength"
         class="reference-hint"
         v-html="refHint('invoice')"></p>
    </div>
    <!----- Employee Roles Toggle Section  ------>
    <template v-if="showRoleToggles">
      <p class="add-employee__togglers-title">{{ $gettext('Assign roles') }}</p>
      <div class="add-employee__togglers-wrapper">
        <div class="add-employee__toggler-cont">
          <p class="add-employee__toggler-name">{{ $gettext('Admin') }}</p>
          <sk-toggler :preselected-value="admin"
                      :item-value="'admin'"
                      @togglerchanged="catchToggler" />
        </div>
        <div class="add-employee__toggler-cont">
          <p class="add-employee__toggler-name">{{ $gettext('Manager') }}</p>
          <sk-toggler :preselected-value="finance"
                      :item-value="'finance'"
                      @togglerchanged="catchToggler" />
        </div>
        <div class="add-employee__toggler-cont">
          <p class="add-employee__toggler-name">{{ $gettext('Coordinator') }}</p>
          <sk-toggler :preselected-value="organizer"
                      :item-value="'organizer'"
                      @togglerchanged="catchToggler" />
        </div>
        <!--      <div class="add-employee__toggler-cont">-->
        <!--        <p class="add-employee__toggler-name">{{ $gettext('Attender') }}</p>-->
        <!--        <sk-toggler :preselected-value="attender"-->
        <!--                    :item-value="'attender'"-->
        <!--                    @togglerchanged="catchToggler" />-->
        <!--      </div>-->
      </div>
    </template>
    <div class="add-employee__submit-btns-cont">
      <button :disabled="!canSendEmail"
              class="sk-btn sk-btn--default add-employee__submit-btn">{{ submitBtnText }}</button>
    </div>
  </form>
</template>)

<script>
  import Vue from 'vue';
  import {mapActions, mapGetters, mapState} from 'vuex';
  import InteractiveEmail from '@/components/enterprise/form_parts/InteractiveEmail';

  export default {
    components: {
      'interactive-email': InteractiveEmail
    },
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        firstName: this.data.currentUser?.firstName || '',
        lastName: this.data.currentUser?.lastName || '',
        email: this.data.currentUser?.email || '',
        errors: {
          firstName: {name: 'firstNameError', errors: []},
          lastName: {name: 'lastNameError', errors: []},
          email: {name: 'emailError', errors: []},
          phoneNumber: {name: 'phoneNumber', errors: []},
          bookingReference: {name: 'bookingReferenceNameError', errors: []},
          paymentBookingReference: {name: 'paymentBookingReferenceNameError', errors: []},
        },
        emailPassServerValidation: false,
        emailIsAvailable: true,
        buyerRef: this.data.currentUser?.bookingReference || '',
        orderRef: this.data.currentUser?.paymentBookingReference || '',
        telCode: this.data.currentUser?.phoneCode || '+47',
        telNumber: this.data.currentUser?.phoneNumber || '',
        admin: this.data.currentUser?.roles.indexOf('admin') === 0,
        finance: this.data.currentUser?.roles.indexOf('finance') != -1,
        organizer: this.data.currentUser?.roles.indexOf('organizer') != -1,
        attender: this.data.currentUser?.roles.indexOf('attender') != -1,
        departments: (() => {
          const departments = this.data.currentUser?.departments || [];
          if (Array.isArray(departments)) {
            return departments.map((item) => item.id);
          }
          return [];
        })(),
        currentDepartment: this.data.currentDepartment,
        departmentReferences: {
          buyer: '',
          order: ''
        },
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userHasBookingRef',
        'userUid',
        'userIsAdminOfEnterprise',
        'userIsManager',
      ]),
      ...mapState('TTEnterpriseInfoStore', {
        lockBookingReference: (state) => state.companyInfo.lockBookingReference,
        lockPaymentBookingReference: (state) => state.companyInfo.lockPaymentBookingReference,
      }),
      ...mapState('EnterpriseStore', {
        departmentsList: ({departments}) => departments || [],
      }),
      isAnyDepartments() { return this.departmentsList && this.departmentsList.length; },
      hideAddDepartment() { return this.data.hideAddDepartment || false; },
      hideRoleToggles() { return this.data.hideRoleToggles || false; },
      showRoleToggles() { return this.userIsAdminOfEnterprise && !this.hideRoleToggles; },
      employeeUid() { return this.data.currentUser ? this.data.currentUser.uid || '' : ''; },
      emailComponent() { return this.data.currentUser ? 'sk-input' : 'interactive-email'; },
      canSendEmail() {
        if (this.data.currentUser) return true;
        return this.$isGodmode() ? this.emailIsAvailable : this.emailPassServerValidation;
      },
      disableBuyerReference() { return this.lockBookingReference && !this.$isGodmode() && this.data.currentUser?.bookingReference != ''; },
      disableOrderReference() { return this.lockPaymentBookingReference && !this.$isGodmode() && this.data.currentUser?.paymentBookingReference != ''; },
      choosenDepartmentsLength() { return this.departments.length; },
      choosenDepartments() { return this.departmentsList.filter((department) => this.departments?.includes(department.id)); },
      firstNameText() { return this.$gettext('First name'); },
      lastNameText() { return this.$gettext('Last name'); },
      emailText() { return this.$gettext('Email*'); },
      countryCodeText() { return this.$gettext('Country code'); },
      phoneText() { return this.$gettext('Phone number'); },
      departmentsText() { return this.$gettext('Department(s)'); },
      bookingRefInfoText() { return this.$gettext('Determined by your company. If you are unsure, please contact the invoice manager in your company for which reference to use.'); },
      submitBtnText() { return this.data.currentUser ? this.$gettext('Save') : this.$gettext('Add employee'); },
      selectedDepartmentText() {
        if (this.currentDepartment) {
          const template = this.$gettext('New employee will be added to <b>"%{departmentName}"</b> department');
          return this.$gettextInterpolate(template, {departmentName: this.currentDepartment.name});
        }
      },
      multipleDepartmentHint() {
        return this.$gettext('Selected departments have their own fixed references set. Your fixed reference above will override the department\'s fixed reference for new orders.');
      },
      buyerDescription() { return this.$store.getters['UserInfoStore/labelForBookingRef'] || this.$gettext('Buyer reference'); },
      orderDescription() { return this.$store.getters['UserInfoStore/labelForPaymentBookingRef'] || this.$gettext('Order reference'); },
    },
    watch: {
      $route() {
        this.closeModal();
      },
    },
    methods: {
      ...mapActions('EnterpriseStore', [
        'inviteEmployee',
        'updateEmployee'
      ]),
      setPreservedData() {
        if (this.data.preservedData) Object.assign(this.$data, this.data.preservedData);
      },
      setError({fieldName, errorText}) {
        this.errors[fieldName].errors.push(errorText);
        this.$store.commit('ErrorsStore/setError', {
          name: this.errors[fieldName].name,
          errors: this.errors[fieldName].errors
        });
      },
      removeErrors() {
        for (const key of Object.keys(this.errors)) {
          this.errors[key].errors = [];
        }
        this.$store.commit('ModalStore/removeModalErrors');
        this.$store.commit('ErrorsStore/removeAllErrors');
      },
      startProgress() {
        this.$store.commit('ModalStore/startModalProgress');
      },
      stopProgress() {
        this.$store.commit('ModalStore/stopModalProgress');
      },
      isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      catchFirstName(value) {
        this.firstName = value;
      },
      catchLastName(value) {
        this.lastName = value;
      },
      catchEmail(value) {
        this.email = value;
      },
      catchValidEmail() {
        this.$store.commit('ModalStore/removeModalErrors');
        this.emailPassServerValidation = true;
        this.emailIsAvailable = true;
      },
      catchInvalidEmail(error) {
        this.$store.commit('ModalStore/removeModalErrors');
        this.emailPassServerValidation = false;
        this.emailIsAvailable = false;
        if (error.errors?.email?.includes('Invalid email')) {
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('Please check the email address you have provided for spelling errors, and try again.'));
        } else if (error.errors?.email.includes('This email is already taken by buyer')) {
          this.emailIsAvailable = true;
          // TODO: Change to a single error with BE
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('This email address has already been registered'));
        } else if (error.errors?.email.includes('This email is already taken by interpreter')) {
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('This email address has already been registered'));
        } else if (error.errors?.email.includes('This email is already taken by the another enterprise member')) {
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('This email address has already been registered'));
        }
      },
      resetEmailErrors() {
        this.$store.commit('ModalStore/removeModalErrors');
      },
      catchTelCode(value) {
        this.telCode = value;
      },
      catchTelNumber(value) {
        this.telNumber = value;
      },
      catchBuyerRef(value) {
        this.buyerRef = value;
      },
      catchOrderRef(value) {
        this.orderRef = value;
      },
      catchToggler(value, name) {
        Vue.set(this, name, value);
      },
      changeDepartmentsHandler(value) {
        if (value.length === 1) {
          const targetDepartment = this.departmentsList.find((department) => department.id === value[0]);
          this.departmentReferences = {
            buyer: targetDepartment.defaultBookingReference,
            order: targetDepartment.defaultPaymentBookingReference
          };
        }
        this.departments = [...value];
      },
      closeModal() {
        this.$emit('closemodal');
      },
      checkForm() {
        let isValidForm = true;
        this.removeErrors();
        this.$store.commit('ModalStore/removeModalErrors');
        if (!this.firstName.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'firstName',
            errorText: this.$gettext('First name is required')
          });
        }
        if (!this.lastName.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'lastName',
            errorText: this.$gettext('Last name is required')
          });
        }
        if (!this.email.trim()) {
          isValidForm = false;
          this.setError({
            fieldName: 'email',
            errorText: this.$gettext('Email is required')
          });
        } else if (!this.isValidEmail(this.email.trim())) {
          isValidForm = false;
          this.setError({
            fieldName: 'email',
            errorText: this.$gettext('Please enter a valid email')
          });
        }
        if (!this.admin && !this.finance && !this.organizer && !this.attender) {
          isValidForm = false;
          this.$scrollToErrors();
          this.$store.commit('ModalStore/setModalErrors', this.$gettext('Please choose at least one role for new employees'));
        }
        if (isValidForm) {
          this.submitForm();
        }
      },
      submitForm() {
        const prevDepartmentState = this.data.currentUser ? this.data.currentUser.departments.map((item) => item.id) || [] : [];
        const currentDepartmentState = this.departments;
        const form = new FormData();
        const submitAction = this.employeeUid ? 'updateEmployee' : 'inviteEmployee';
        let params = form;
        if (this.employeeUid) {
          params = {
            form,
            id: this.employeeUid,
          };

          form.append('employee[uid]', this.employeeUid);

          if (this.userIsManager) params.enterpriseId = this.data.currentUser.enterprise.id;
        }
        form.append('employee[firstName]', this.firstName.trim());
        form.append('employee[lastName]', this.lastName.trim());
        if (this.telCode && this.telNumber) {
          form.append('employee[phoneCode]', this.telCode);
          form.append('employee[phoneNumber]', this.telNumber.trim());
        }
        if (this.currentDepartment && this.currentDepartment.id) {
          form.append('employee[departments][][id]', this.currentDepartment.id);
        } else if (this.departments && this.departments.length) {
          for (const dep of this.departments) {
            form.append('employee[departments][][id]', dep);
          }
        }
        form.append('employee[defaultBookingReference]', this.buyerRef.trim());
        form.append('employee[defaultPaymentBookingReference]', this.orderRef.trim());
        form.append('employee[email]', this.email.toLowerCase().trim());
        if (this.admin) {
          form.append('employee[roles][]', 'admin');
        }
        if (this.finance) {
          form.append('employee[roles][]', 'finance');
        }
        if (this.organizer) {
          form.append('employee[roles][]', 'organizer');
        }
        if (this.attender) {
          form.append('employee[roles][]', 'attender');
        }
        this.$emit('startprogress');
        this[submitAction](params).then((data) => {
          if (this.data.context && this.data.context[this.data.successHandlingCallbackName]) {
            this.data.context[this.data.successHandlingCallbackName](data.employee.uid);
          }
          this.trackEnterpriseGA(prevDepartmentState, currentDepartmentState);
          this.$emit('stopprogress');
          this.closeModal();
          if (!this.$route.path.includes('manage_employees')) window.location.reload();
        }).catch((error) => {
          this.$emit('stopprogress');
          this.handleError(error);
        });
      },
      handleError(error) {
        if (error && error?.status == 401) {
          Promise.all([
            this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid),
            this.$store.dispatch('EnterpriseStore/getEmployees')
          ]).then(() => {
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Sorry, you don\'t have rights to manage employees. Please contact an administrator.')
              });
            }, 0);
          });
        } else if (error
          && error.data
          && error.data.errors) {
          if (error.data?.errors[0]?.defaultBookingReference?.[0] === 'Cannot modify locked reference') {
            this.setError({
              fieldName: 'bookingReference',
              errorText: this.$gettext('Cannot modify locked booking reference.')
            });
          }
          if (error.data?.errors[0]?.defaultPaymentBookingReference?.[0] === 'Cannot modify locked reference') {
            this.setError({
              fieldName: 'paymentBookingReference',
              errorText: this.$gettext('Cannot modify locked payment booking reference.')
            });
          }
          if (error.data.errors.length
            && error.data.errors[0]?.enterpriseId?.includes('Email is already registered')) {
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('This email address has already been registered')
            });
          } else if (error.data.errors.email
            && error.data.errors.email.length
            && error.data.errors?.email?.includes('Invalid email')) {
            this.setError({
              fieldName: 'email',
              errorText: this.$gettext('Please check the email address you have provided for spelling errors, and try again.')
            });
          } else if (error.data.errors.length
            && error.data.errors[0].phoneNumber[0] === 'Invalid phone') {
            this.setError({
              fieldName: 'phoneNumber',
              errorText: this.$gettext('Please check the phone number you provided for any errors and try again.')
            });
          } else if (error.data.errors.department_jobs
            && error.data.errors.department_jobs.length
            && error.data.errors?.department_jobs?.includes('There are attended jobs for')) {
            this.$store.dispatch('ModalStore/closeModal');
            const template = this.$gettext('This user is part of several assignments for this department. To remove them, please contact us at <a class="sk-link" href="mailto:%{support}">%{support}</a>');
            const infoMessage = this.$gettextInterpolate(template, {support: this.$getString('domainData', 'supportEmail')});
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {text: infoMessage});
            }, 0);
          } else if (error.data
            && error.data.errors
            && error.data.errors.includes("you don't have update_employee rights on this member")) {
            this.$store.dispatch('ModalStore/closeModal');
            const infoMessage = this.$gettext('You do not have the correct rights to update this member. Please contact the administrator of your enterprise.');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {text: infoMessage});
            }, 0);
          }
        } else {
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
      },
      trackEnterpriseGA(prevDepartmentState, currentDepartmentState) {
        this.trackEmployeeInDepartment(prevDepartmentState, currentDepartmentState);
        this.employeeUid
          ? this.$skGA.emitEvent(this.$GADataConstructor.getEnterpriseObjGA('employee', 'edit'))
          : this.$skGA.emitEvent(this.$GADataConstructor.getEnterpriseObjGA('employee', 'add'));
      },
      trackEmployeeInDepartment(prevDepartmentsState, currentDepartmentsState) {
        currentDepartmentsState.forEach((item) => {
          if (prevDepartmentsState.indexOf(item) === -1) {
            this.$skGA.emitEvent(this.$GADataConstructor.getEnterpriseObjGA('employee', 'add_to_department'));
          }
        });
        prevDepartmentsState.forEach((item) => {
          if (currentDepartmentsState.indexOf(item) === -1) {
            this.$skGA.emitEvent(this.$GADataConstructor.getEnterpriseObjGA('employee', 'remove_from_department'));
          }
        });
      },
      openAddDepartmentModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-department',
          width: 410,
          data: {
            title: this.$gettext('New department'),
            context: this,
            fromModal: {
              title: this.data.title,
              component: 'add-employee',
            },
            componentData: this.$data
          }
        });
      },
      refHint(type) {
        let hint = '';
        const reference = type === 'order' ? this.departmentReferences.buyer : this.departmentReferences.order;
        const isReferencesEmpty = this.choosenDepartments.every((department) => {
          return department[type === 'order' ? 'defaultBookingReference' : 'defaultPaymentBookingReference'] === '';
        });

        if (this.choosenDepartmentsLength === 1 && reference) {
          const template = this.$gettext('The selected department has a fixed reference: <strong>%{reference}</strong><br> The reference stated above will override the department\'s fixed reference in the event of a new order.');
          hint = this.$gettextInterpolate(template, {reference});
        } else if (this.choosenDepartmentsLength > 1 && !isReferencesEmpty) {
          hint = this.multipleDepartmentHint;
        } else {
          hint = this.$gettext('The reference stated above will override the department\'s fixed reference in the event of a new order.');
        }
        return hint;
      },
      setDepartmentRefs() {
        if (this.choosenDepartmentsLength === 1) {
          this.departmentReferences = {
            buyer: this.data.currentUser?.departments[0].default_booking_reference,
            order: this.data.currentUser?.departments[0].default_payment_booking_reference
          };
        }
      }
    },
    mounted() {
      this.setPreservedData();
      this.setDepartmentRefs();
    },
    beforeMount() {
      this.removeErrors();
    },
    beforeDestroy() {
      this.$store.commit('EnterpriseStore/removeEmailQuery');
    }
  };
</script>

<style scoped>
.add-employee__main-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 30px 30px;
}

.add-employee__extra-text {
  margin: 0 auto 20px;
  font-size: 12px;
  text-align: center;
}

.add-employee__half-width {
  width: calc(50% - 10px);
}

.add-employee__tel-row {
  display: flex;
  width: 100%;
}

.add-employee__departments-row {
  display: flex;
  width: 100%;
  margin: 15px 0;
}

.add-employee__departments {
  width: 100%;
}

.add-employee__booking-ref {
  width: auto;
  margin: 12px 0 8px 27px;
}

.reference-hint {
  margin-left: 27px;
  font-size: 12px;
}

.reference-hint--enterprise {
  margin-top: 10px;
}

.add-employee__code-select {
  flex-shrink: 0;
  width: 85px;
  margin-right: 10px;
  margin-bottom: 0;
}

.add-employee__number-input {
  width: calc(100% - 95px);
  margin-bottom: 0;
}

.add-employee__togglers-title {
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.add-employee__togglers-wrapper {
  display: flex;
  width: 100%;
}

.add-employee__toggler-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25%;
}

.add-employee__toggler-name {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 10px;
}

.add-employee__submit-btns-cont {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 35px;
}

.add-employee__submit-btn {
  display: inline-block;
  width: auto;
  margin-left: 15px;
  padding: 0 10px;
}

.add-employee__info {
  width: 100%;
  margin: 10px 0 15px 0;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--color-warning-200, #f0e8dd);
}

.add-employee__info--note {
  margin: -8px -8px 0 -8px;
}

.sk-select-btn {
  margin-top: 21.6px;
}

@media (max-width: 767px) {
  .add-employee__main-wrapper {
    padding: 15px;
  }

  .sk-select-btn {
    margin-top: 19px;
  }

  .sk-select-btn__add-department {
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  .add-employee__submit-btn {
    margin-bottom: 15px;
  }
}
</style>

<style>
.add-employee__booking-ref > .sk-input__input-wrapper {
  background-color: #fff;
}
</style>
