const statistics = {
  methods: {
    getBookedInterpreters(enterpriseId, dateFrom, dateTo) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/enterprises/${enterpriseId}/statistics_filters/booked_interpreters?date_from=${dateFrom}&date_to=${dateTo}`)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBookedTranslators(enterpriseId, dateFrom, dateTo) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/enterprises/${enterpriseId}/statistics_filters/booked_translators?date_from=${dateFrom}&date_to=${dateTo}`)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getStatistics(path) {
      return new Promise((resolve, reject) => {
        this.ajax.get(path, {headers: {Accept: 'application/json'}})
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    downloadStatistics(path) {
      return new Promise((resolve, reject) => {
        this.ajax.get(path)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            this.resolveError403(error, resolve);
            reject(error);
          });
      });
    },
    getAccessPowerBIData(enterpriseId) {
      return new Promise((resolve, reject) => {
        this.ajax.get(`/v1/enterprises/${enterpriseId}/power_bi/embedded_report`)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};

export default statistics;
